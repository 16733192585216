import { Progress } from 'antd';
import style from './untils.module.scss';
import clsx from 'clsx';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

export const JUDICIAL_SEARCH_NAME = 'judicial_records_search';
export const CITIZEN_PROFILE_REQUEST_NAME = 'citizen_profile_request_request';
export const CITIZEN_PROFILE_ORGANIZATION_NAME =
  'citizen_profile_request_organization_request_list';
export const VERDICT_DOCUMENT_REQUEST_LIST_NAME =
  'verdict_document_request_list';
export const RELATED_DOCUMENT_REQUEST_LIST_NAME =
  'related_document_request_list';

export const PROHIBIT_POSITION_DOCUMENT_REQUEST_LIST =
  'prohibit_position_document_request_list';
export const CITIZEN_DOCUMENT_NAME =
  'citizen_profile_request_document_request_list';

export const KEY_GROUP_TYPE = {
  VERDICT: 'verdict',
  PROHIBIT: 'prohibit_position',
  RELATE_DOCUMENT: 'relate_document',
};

export const KEY_STATUS_CREATE_JUDICIAL_RECORD = {
  WAITING: 'created',
  VERIFIED: 'verified',
  IM_COMPLETED: 'drafting',
  APPROVED: 'approved',
  PENDING: 'pending',
  MINISTRY: 'ministry',
  DONE: 'done',
  ERROR: 'error',
};

export const renderSample = (data) => {
  if (data === 2) return 'Mẫu phiếu LLTP 2';
  return 'Mẫu phiếu LLTP 1';
};

// check trang thai sync
export const generatorStatusSync = (status, textError = '') => {
  // cho dong bo
  if (status.toLowerCase() === 'null') {
    return (
      <div className={clsx(style.areSynchronizing)}>
        <Progress percent={0} status='active' showInfo={false} />
        <span className={clsx(style.text)}>Chờ đồng bộ</span>
      </div>
    );
  }
  // dang dong bo
  else if (status.toLowerCase() === 'pending') {
    return (
      <div className={clsx(style.areSynchronizing)}>
        <Progress percent={70} status='active' showInfo={false} />
        <span className={clsx(style.text)}>Đang đồng bộ</span>
      </div>
    );
  }
  // dong bo thanh cong
  else if (status.toLowerCase() === 'done') {
    return (
      <div className={clsx(style.sync_success)}>
        <CheckCircleFilled
          className={clsx(style.personalInformation_icon_success)}
        />
        <span className={clsx(style.personalInformation_text)}>Đã đồng bộ</span>
      </div>
    );
  } else if (status.toLowerCase() === 'wait_for_result') {
    return (
      <div className={clsx(style.areSynchronizing)}>
        <Progress percent={0} status='active' showInfo={false} />
        <span className={clsx(style.text)}>Đợi kết quả phản hồi từ bộ</span>
      </div>
    );
  } else {
    // dong bo that bai
    return (
      <div className={clsx(style.sync_success)}>
        <CloseCircleFilled
          className={clsx(style.personalInformation_icon_error)}
        />
        <span className={clsx(style.personalInformation_text)}>
          Lỗi: {textError}
        </span>
      </div>
    );
  }
};
