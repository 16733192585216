/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import { getRouteHorMenus } from 'src/@crema/utility/HorizontalMenuUtils';
import PropTypes from 'prop-types';
import './index.style.less';
import routeConfig from 'src/pages/routeConfig';
import { getOpenKeys } from 'src/shared/utils/Route';

const AppHorizontalNav = ({ className }) => {
  const { pathname } = useLocation();
  const selectedKeys = getOpenKeys(pathname, routeConfig) || [];
  const defaultOpenKeys = selectedKeys[0];
  const { user } = useAuthUser();
  const userPermissions = user?.permissions || [];

  const [openKeys, setOpenKeys] = useState([defaultOpenKeys]);

  useEffect(() => {
    setOpenKeys([selectedKeys[selectedKeys.length - 2]]);
  }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      mode='horizontal'
      className={className}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
      selectedKeys={[selectedKeys[selectedKeys.length - 1]]}>
      {getRouteHorMenus(userPermissions)}
    </Menu>
  );
};

export default AppHorizontalNav;
AppHorizontalNav.propTypes = {
  className: PropTypes.string,
};
