export const FORM_TYPES = {
  ADD: 'add',
  UPDATE: 'update',
  COPY: 'copy',
  INFO: 'info',
  REFUSE_AMBASSADOR: 'refuse_ambassador',
  REGISTER_AMBASSADOR: 'register_ambassador',
  CHANGE_CONTENT_PROJECT: 'change_content_project',
};

export const FORM_CONFIG = [
  {
    type: FORM_TYPES.ADD,
    title: 'form.formTitleAdd',
    buttonText: 'form.buttonAdd',
    successMessage: 'form.addSuccessMessage',
  },
  {
    type: FORM_TYPES.UPDATE,
    title: 'form.formTitleUpdate',
    buttonText: 'form.buttonUpdate',
    successMessage: 'form.updateSuccessMessage',
  },
  {
    type: FORM_TYPES.COPY,
    title: 'form.formTitleCopy',
    buttonText: 'form.buttonCopy',
    successMessage: 'form.CopySuccessMessage',
  },
  {
    type: FORM_TYPES.INFO,
    title: 'form.formTitleInfo',
    buttonText: '',
    successMessage: '',
  },
  {
    type: FORM_TYPES.REGISTER_AMBASSADOR,
    title: 'ambassador.formRegister',
    configCancelText: 'dialog.button.close',
    successMessage: '',
  },
  {
    type: FORM_TYPES.REFUSE_AMBASSADOR,
    title: 'ambassador.formTitle.refuse',
    buttonText: 'ambassador.buttonText.refuse',
    successMessage: 'ambassador.successMessage.refuse',
  },
  {
    type: FORM_TYPES.CHANGE_CONTENT_PROJECT,
    title: 'project.change.content.title',
    buttonText: 'project.change.content.buttonText',
    successMessage: 'project.change.content.success',
  },
];
