import React from 'react';
import config from 'src/config';
import { ContainerOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

export const AppGuideCenter = ({ isWorkspace }) => {
  const nav = useNavigate();
  return (
    <Tooltip title={'Hướng dẫn sử dụng'}>
      <ContainerOutlined
        style={{
          fontSize: 20,
          marginLeft: isWorkspace ? 12 : 0,
          color: isWorkspace ? '#FFFFFF' : '#000000',
        }}
        onClick={() => nav(config.routes.usageGuideView)}
      />
    </Tooltip>
  );
};
