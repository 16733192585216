import React from 'react';

import config from 'src/config/index';

import Icon from '@ant-design/icons';

import {
  AuditIcon,
  DatabaseIcon,
  FileSyncIcon,
  FolderIcon,
  FundProjectScreenIcon,
  ToolIcon,
} from 'src/assets/icon/sidebar';

export const ROUTER_NAME = {
  USER_MANUAL: 'user-manual',
  DATA_FLIE: 'data-file',
  DATA_API: 'data-API',
  CONFIG_API_DIGITAL: 'config-API-digital',
  CONFIG_INTEGRATE: 'config-integrate',
  PUSH_API: 'push-API',
  RECEIVE_API: 'receive-API',
  CARD_ISSURE: 'card-issure',
  CARD_ISSURE_BY_USER: 'card-issure-by-user',
  STATISTIC: 'statistic',
  REPORT_SYSTEM: 'report-system',
  SYNTHESIS_REPORT: 'synthesis-report',
  ORGANIZATION: 'organization',
  DEPARTMENT: 'department',
  DOCUMENT_TYPE: 'document-type',
  RAW_DOCUMENT: 'raw-document',
  RAW_DOCUMENT_POLICE: 'raw-document-police',
  RAW_DOCUMENT_COURT: 'raw-document-court',
  RAW_DOCUMENT_JUSTICE_LGSP: 'raw-document-justice-lgsp',
  RAW_DOCUMENT_JUDGMENT_EXECUTION: 'raw-document-judgment-execution',
  RAW_DOCUMENT_CONTROL_INSTITUTE: 'raw-document-control-institute',
  JUDICIAL_CREATE_RECORDS: 'judicial-create-records',
  JUDICIAL_VERIFY_RECORD: 'judicial-verify-record',
  LIST_JUDICIAL_RECORD: 'list-judicial-record',
  FORM_SETTING_ALL: 'form-setting-all',
  FORM_SETTING_VERDICT: 'form-setting-verdict',
  FORM_SETTING_POLICE: 'form-setting-police',
  FORM_SETTING_COURT: 'form-setting-court',
  FORM_SETTING_PROCURACY: 'form-setting-procuracy',
  FORM_SETTING_EXECUTION_JUDGMENT: 'form-setting-execution-judgment',
  FORM_SETTING_DECISION_JUDICIARY: 'form-setting-decision-judiciary',
  SERVICE_PARAMS_CONFIG: 'service-params-config',
  JUDICIAL_RECORDS: 'judicial-records',
  HELP_QA: 'help-qa',
  USAGE_GUIDE_MANAGE: 'usage-guide-manage',
  MANAGE_API_PUSH: 'manage-api-push',
  RECEIVE_CONNECT_API: 'receive-connect-api',
  WAITING_DIGITIZE_DOCUMENT: 'waiting-digitize-document',
  WAITING_CONFIRM_DIGITIZE_DOCUMENT:
    'list-digitized-documents-waiting-confirmation',
  USER_ACTION_LOG: 'user-action-log',
  USER_ACCESS_LOG: 'user-access-log',
  SETTING_LOG: 'setting-log',
  LOGIN_STATUS_LOG: 'login-status-log',

  SHARE_API_CONFIG_ALL: 'share-api-config-all',
  SHARE_API_CONFIG_DISTRICT: 'share-api-config-district',
  SHARE_API_CONFIG_LGSP: 'share-api-config-lgsp',
  SHARE_API_CONFIG_PROVINCE: 'share-api-config-province',
  SHARE_API_CONFIG_DEPARTMENT: 'share-api-config-department',
  SHARE_API_CONFIG_CITY: 'share-api-config-city',
  SHARE_API_CONFIG_PROVINCE_INFORMATION_PORTAL:
    'share-api-config-province-information-portal',
  SHARE_API_CONFIG_PROCUREMENT: 'share-api-config-procurement',
  SHARE_API_CONFIG_COURT: 'share-api-config-court',
  SHARE_API_CONFIG_THA: 'share-api-config-tha',
  SHARE_API_CONFIG_POLICE: 'share-api-config-police',
  USERS: 'users',
  ROLE: 'role',
  PERMISSION: 'permission',
  DATA_UPLOAD: 'data-upload',
  DATA_WAREHOUSE: 'data-warehouse',
  DATA_AIRFLOW: 'data-airflow',
  MONITORING_SYSTEM: 'monitoring-system',
  LIST_SOURCE_DATA: 'list-source-data',
  CONFIG_DATA_PROCESS: 'config-data-process',
  LIST_SOURCE_API: 'list-source-api',
  CONFIG_API_PROCESS: 'config-api-process',
};

const styleIcon = {
  color: 'white',
};

// const styleIconColor = {
//   color: '#333333',
// };

const routesConfig = [
  {
    id: 'stream_data_group',
    messageId: 'sidebar.stream_get_from_file',
    icon: <Icon style={styleIcon} component={FileSyncIcon} />,
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.LIST_SOURCE_DATA,
        messageId: 'sidebar.configureDataRetrievalProcess',
        type: 'item',
        path: config.routes.configDataProcess,
      },
      {
        id: ROUTER_NAME.CONFIG_DATA_PROCESS,
        type: 'item',
        messageId: 'sidebar.management_data_source',
        path: config.routes.listSourceData,
      },
      {
        id: ROUTER_NAME.LIST_SOURCE_API,
        messageId: 'sidebar.list_source_api',
        type: 'item',
        path: config.routes.configApiProcess,
      },
      {
        id: ROUTER_NAME.CONFIG_API_PROCESS,
        type: 'item',
        messageId: 'sidebar.config_api_progress',
        path: config.routes.listSourceApi,
      },
    ],
  },
  {
    id: 'synthesis_group',
    messageId: 'sidebar.data_synthesis',
    icon: <Icon style={styleIcon} component={DatabaseIcon} />,
    type: 'group',
    children: [
      {
        id: ROUTER_NAME.DATA_FLIE,
        type: 'item',
        messageId: 'sidebar.data_file',
        path: 'https://cloud.mhdigital.vn',
      },
      {
        id: ROUTER_NAME.DATA_API,
        type: 'item',
        messageId: 'sidebar.data_api',
        path: 'https://airflow.mhdigital.vn',
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT,
        type: 'item',
        messageId: 'sidebar.rawDocument',
        path: config.routes.rawDocument,
      },
    ],
  },
  {
    id: 'digitize_group',
    messageId: 'sidebar.digitize',
    icon: <Icon style={styleIcon} component={FileSyncIcon} />,
    type: 'group',
    children: [
      {
        id: 'digitized_awaiting_data',
        messageId: 'sidebar.config_API_digital',
        type: 'group',
        children: [
          {
            id: ROUTER_NAME.MANAGE_API_PUSH,
            type: 'item',
            messageId: 'sidebar.push_connect_api',
            path: config.routes.pushConnectApi,
          },
          {
            id: ROUTER_NAME.RECEIVE_CONNECT_API,
            type: 'item',
            messageId: 'sidebar.receive_connect_api',
            path: config.routes.receiveConnectApi,
          },
        ],
      },
      {
        id: 'setting_form_template',
        messageId: 'sidebar.setting_form_template',
        type: 'group',
        children: [
          {
            id: ROUTER_NAME.FORM_SETTING_ALL,
            type: 'item',
            messageId: 'sidebar.form_setting_all',
            path: config.routes.formSetting,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_VERDICT,
            type: 'item',
            messageId: 'sidebar.form_setting_verdict',
            path: config.routes.formSettingVerdict,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_POLICE,
            type: 'item',
            messageId: 'sidebar.form_setting_police',
            path: config.routes.formSettingPolice,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_COURT,
            type: 'item',
            messageId: 'sidebar.form_setting_court',
            path: config.routes.formSettingCourt,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_PROCURACY,
            type: 'item',
            messageId: 'sidebar.form_setting_procuracy',
            path: config.routes.formSettingProcuracy,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_EXECUTION_JUDGMENT,
            type: 'item',
            messageId: 'sidebar.form_setting_execution_judgment',
            path: config.routes.formSettingExecutionJudgment,
          },
          {
            id: ROUTER_NAME.FORM_SETTING_DECISION_JUDICIARY,
            type: 'item',
            messageId: 'sidebar.form_setting_decision_judiciary',
            path: config.routes.formSettingDecisionJudiciary,
          },
          {
            id: ROUTER_NAME.WAITING_DIGITIZE_DOCUMENT,
            type: 'item',
            messageId: 'sidebar.list_waiting_digital',
            path: config.routes.listWaitingDigital,
          },
          {
            id: ROUTER_NAME.WAITING_DIGITIZE_DOCUMENT,
            type: 'item',
            messageId: 'sidebar.list_digitized_documents_waiting_confirm',
            path: config.routes.listDocumentWaitingConfirm,
          },
        ],
      },
      {
        id: ROUTER_NAME.SERVICE_PARAMS_CONFIG,
        messageId: 'sidebar.service_params_config',
        type: 'item',
        path: config.routes.serviceParamsConfig,
      },
    ],
  },
  {
    id: 'record_management',
    messageId: 'sidebar.record_management',
    type: 'group',
    icon: <Icon style={styleIcon} component={FolderIcon} />,
    children: [
      {
        id: ROUTER_NAME.CONFIG_INTEGRATE,
        type: 'item',
        messageId: 'sidebar.config_integrated',
        path: config.routes.formSetting,
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT_POLICE,
        type: 'item',
        messageId: 'sidebar.police_record',
        path: config.routes.policeRecord,
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT_COURT,
        type: 'item',
        messageId: 'sidebar.court_record',
        path: config.routes.courtRecord,
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT_JUSTICE_LGSP,
        type: 'item',
        messageId: 'sidebar.justice_department_record',
        path: config.routes.justiceDepartmentRecord,
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT_JUDGMENT_EXECUTION,
        type: 'item',
        messageId: 'sidebar.judgment_execution_record',
        path: config.routes.judgmentExecutionRecord,
      },
      {
        id: ROUTER_NAME.RAW_DOCUMENT_CONTROL_INSTITUTE,
        type: 'item',
        messageId: 'sidebar.control_institution_record',
        path: config.routes.controlInstituteRecord,
      },
    ],
  },
  {
    id: 'judicial_record',
    messageId: 'sidebar.judicial_record',
    type: 'group',
    icon: <Icon style={styleIcon} component={AuditIcon} />,
    children: [
      {
        id: ROUTER_NAME.JUDICIAL_CREATE_RECORDS,
        type: 'item',
        messageId: 'sidebar.judicial_record.create',
        path: config.routes.judicialCreateRecord,
      },
      {
        id: ROUTER_NAME.JUDICIAL_VERIFY_RECORD,
        type: 'item',
        messageId: 'sidebar.judicial_record.verify',
        path: config.routes.judicialRecordVerify,
      },
      {
        id: ROUTER_NAME.LIST_JUDICIAL_RECORD,
        type: 'item',
        messageId: 'sidebar.list_general_criminal_record',
        path: config.routes.judicialListRecord,
      },
      {
        id: ROUTER_NAME.CARD_ISSURE_BY_USER,
        type: 'item',
        messageId: 'sidebar.card_issuedByName',
        path: config.routes.searchJudicialRecordsByName,
      },
      {
        id: ROUTER_NAME.CARD_ISSURE,
        type: 'item',
        messageId: 'sidebar.card_issuedByCCCD',
        path: config.routes.searchJudicialRecordsByIdCard,
      },
    ],
  },
  {
    id: 'reporting_data_group',
    messageId: 'sidebar.reporting_data_mining',
    icon: <Icon style={styleIcon} component={FundProjectScreenIcon} />,
    type: 'group',
    children: [
      // bao cao tong hop
      {
        id: ROUTER_NAME.REPORT_SYSTEM,
        type: 'item',
        messageId: 'sidebar.synthesis_report',
        path: config.routes.synthesisReport,
      },
      {
        id: ROUTER_NAME.STATISTIC,
        type: 'item',
        messageId: 'sidebar.statistic',
        path: 'https://superset.mhdigital.vn',
      },
      {
        id: ROUTER_NAME.REPORT_SYSTEM,
        type: 'item',
        messageId: 'sidebar.reporting_system',
        path: config.routes.systemMonitoringReport,
      },
      {
        id: 'share_api_config',
        messageId: 'sidebar.lltp_share_api',
        type: 'group',
        children: [
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_ALL,
            type: 'item',
            messageId: 'sidebar.share_api_list',
            path: config.routes.shareApiConfig,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_DISTRICT,
            type: 'item',
            messageId: 'sidebar.district_share_api',
            path: config.routes.shareApiDistrict,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_LGSP,
            type: 'item',
            messageId: 'sidebar.department_share_api',
            path: config.routes.shareApiDepartment,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_PROVINCE,
            type: 'item',
            messageId: 'sidebar.province_share_api',
            path: config.routes.shareApiProvince,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_DEPARTMENT,
            type: 'item',
            messageId: 'sidebar.lgsp_share_api',
            path: config.routes.shareApiLgsp,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_CITY,
            type: 'item',
            messageId: 'sidebar.city_share_api',
            path: config.routes.shareApiCity,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_PROVINCE_INFORMATION_PORTAL,
            type: 'item',
            messageId: 'sidebar.provincial_information_portal_share_api',
            path: config.routes.shareApiProvinceInformation,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_PROCUREMENT,
            type: 'item',
            messageId: 'sidebar.other_systems_procuracy_share_api',
            path: config.routes.shareApiProcurement,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_COURT,
            type: 'item',
            messageId: 'sidebar.other_systems_court_share_api',
            path: config.routes.shareApiCourt,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_THA,
            type: 'item',
            messageId: 'sidebar.other_systems_tha_share_api',
            path: config.routes.shareApiTha,
          },
          {
            id: ROUTER_NAME.SHARE_API_CONFIG_POLICE,
            type: 'item',
            messageId: 'sidebar.other_systems_police_share_api',
            path: config.routes.shareApiPolice,
          },
        ],
      },
    ],
  },
  {
    id: 'background_tool',
    messageId: 'sidebar.background_tool',
    type: 'group',
    icon: <Icon style={styleIcon} component={ToolIcon} />,
    children: [
      {
        id: ROUTER_NAME.ORGANIZATION,
        type: 'item',
        messageId: 'sidebar.organization',
        path: config.routes.organization,
      },
      {
        id: ROUTER_NAME.DEPARTMENT,
        type: 'item',
        messageId: 'sidebar.subset_organization_department',
        path: config.routes.subsetOrganization,
      },
      {
        id: ROUTER_NAME.USERS,
        type: 'item',
        messageId: 'sidebar.user_list',
        path: config.routes.userManage,
      },
      {
        id: ROUTER_NAME.ROLE,
        type: 'item',
        messageId: 'sidebar.admin_role',
        path: config.routes.adminRole,
      },
      {
        id: ROUTER_NAME.PERMISSION,
        type: 'item',
        messageId: 'sidebar.admin_permission',
        path: config.routes.adminPermission,
      },
      {
        id: ROUTER_NAME.USAGE_GUIDE_MANAGE,
        type: 'item',
        messageId: 'sidebar.list_usage',
        path: config.routes.usageGuideManage,
      },
      {
        id: ROUTER_NAME.HELP_QA,
        type: 'item',
        messageId: 'sidebar.list_qa',
        path: config.routes.listQA,
      },
      {
        id: 'system_log',
        messageId: 'sidebar.system_log',
        type: 'group',
        children: [
          {
            id: ROUTER_NAME.SETTING_LOG,
            type: 'item',
            messageId: 'sidebar.setting_log',
            path: config.routes.settingLog,
          },
          {
            id: ROUTER_NAME.USER_ACTION_LOG,
            type: 'item',
            messageId: 'sidebar.user_action_log',
            path: config.routes.userActionLog,
          },
          {
            id: ROUTER_NAME.USER_ACCESS_LOG,
            type: 'item',
            messageId: 'sidebar.access_log',
            path: config.routes.accessLog,
          },
          {
            id: ROUTER_NAME.LOGIN_STATUS_LOG,
            type: 'item',
            messageId: 'sidebar.login_status_log',
            path: config.routes.loginStatus,
          },
        ],
      },
    ],
  },
];

export default routesConfig;
