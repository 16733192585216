import React, { memo, useEffect, useState } from 'react';
import { Modal, Result } from 'antd';
import style from './SyncError.module.scss';
import clsx from 'clsx';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/src/simplebar.css';
import AntButton from 'src/@crema/component/AntButton';

function SyncError({
  resultDataSyncStatus,
  client_id,
  setIsLoading,
  setIsSyncError,
  setIsReSync,
  isSyncError,
  loading,
  handleSyncMinistryRetry,
}) {
  const [messageErrorPersonalInfo, setMessageErrorPersonalInfo] = useState([]);
  const [messageErrorVerdicts, setMessageErrorVerdicts] = useState([]);
  const [
    messageErrorBannedHoldingPositions,
    setMessageErrorBannedHoldingPositions,
  ] = useState([]);

  const listErrorSync = [
    ...messageErrorPersonalInfo,
    ...messageErrorVerdicts,
    ...messageErrorBannedHoldingPositions,
  ];

  // get lay error message
  useEffect(() => {
    if (resultDataSyncStatus) {
      const syncPersonalInformation = resultDataSyncStatus?.status;
      const checkErrorVerdicts = resultDataSyncStatus?.verdict_jobs
        ? resultDataSyncStatus?.verdict_jobs?.filter((item) => {
            return item?.status.trim().toLowerCase() === 'fail';
          })
        : [];

      const checkErrorBannedHoldingPositions =
        resultDataSyncStatus?.prohibit_jobs
          ? resultDataSyncStatus?.prohibit_jobs?.filter((item) => {
              return item?.status.trim().toLowerCase() === 'fail';
            })
          : [];
      if (
        syncPersonalInformation.trim().toLowerCase() === 'fail' ||
        checkErrorVerdicts?.length > 0 ||
        checkErrorBannedHoldingPositions.length > 0
      ) {
        setIsSyncError(true);
        // kiem tra message error sync thong tin nhan than
        if (syncPersonalInformation.trim().toLowerCase() === 'fail') {
          setMessageErrorPersonalInfo(['Lỗi đồng bộ thông tin nhân thân']);
        } else {
          setMessageErrorPersonalInfo([]);
        }

        // kiem tra loi thong tin ban an
        if (checkErrorVerdicts?.length > 0) {
          const listErrorsVerdicts = checkErrorVerdicts?.map((item) => {
            return `Lỗi đồng bộ thông tin bản án số ${item?.job_id}`;
          });
          setMessageErrorVerdicts(listErrorsVerdicts);
        } else {
          setMessageErrorVerdicts([]);
        }

        // kiem tra Thông tin Cấm đảm nhiệm chức vụ
        if (checkErrorBannedHoldingPositions?.length > 0) {
          const listErrorsBannedHoldingPosition =
            checkErrorBannedHoldingPositions?.map((item) => {
              return `Lỗi đồng bộ Thông tin Cấm đảm nhiệm chức vụ số ${item?.job_id}`;
            });
          setMessageErrorBannedHoldingPositions(
            listErrorsBannedHoldingPosition,
          );
        } else {
          setMessageErrorBannedHoldingPositions([]);
        }
      } else {
        setIsSyncError(false);
      }
    }
  }, [resultDataSyncStatus]);

  const handleCancelModal = () => {
    setIsSyncError(false);
    setIsReSync(true);
  };
  return (
    <>
      <Modal
        open={isSyncError}
        onOk={() => {
          handleSyncMinistryRetry({
            id: resultDataSyncStatus?.citizen_profile_id,
            client: client_id ? client_id : 'stp.mhdigital.vn',
          });
        }}
        onCancel={handleCancelModal}
        footer={null}
        centered
        className={clsx(style.syncError_modal)}
        closable={false}>
        <Result
          status='error'
          title='Lỗi đồng bộ'
          subTitle={
            <SimpleBarReact
              style={{
                maxHeight: 350,
              }}>
              {listErrorSync?.map((item, index) => {
                return (
                  <p className={clsx(style.contentError)} key={index}>
                    {item}
                  </p>
                );
              })}
            </SimpleBarReact>
          }
          extra={[
            <AntButton key='btn-cancel-modal' onClick={handleCancelModal}>
              Đóng
            </AntButton>,
            <AntButton
              loading={loading}
              key='btn-ok-modal'
              onClick={() => {
                setIsLoading(true);
                handleSyncMinistryRetry({
                  id: resultDataSyncStatus?.citizen_profile_id,
                  client: client_id ? client_id : 'stp.mhdigital.vn',
                });
              }}
              type='primary'>
              Đồng bộ lại
            </AntButton>,
          ]}></Result>
      </Modal>
    </>
  );
}

export default memo(SyncError);
