/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { getRouteMenus } from 'src/@crema/utility/VerticalMenuUtils';
import clsx from 'clsx';
import './index.style.less';
import defaultConfig from '../../../../utility/AppContextProvider/defaultConfig';
import { useSidebarContext } from 'src/@crema/utility/AppContextProvider/SidebarContextProvider';
import { MenuStyle } from 'src/shared/constants/AppEnums';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import routeConfig from 'src/pages/routeConfig';
import { getOpenKeys } from 'src/shared/utils/Route';

const AppVerticalNav = ({ refScrollMenu }) => {
  const { menuStyle, sidebarColorSet } = useSidebarContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user } = useAuthUser();
  const userPermissions = user?.permissions || [];
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState();

  const updateHeightScrollMenu = () => {
    setTimeout(() => {
      if (refScrollMenu?.current?.recalculate) {
        refScrollMenu?.current?.recalculate();
      }
    }, 200);
  };

  useEffect(() => {
    const selectedKeys = getOpenKeys(pathname, routeConfig) || [];
    if (selectedKeys && selectedKeys.length > 1) {
      setSelectedKey(selectedKeys[selectedKeys.length - 1]);
      setOpenKeys(selectedKeys.slice(0, selectedKeys.length - 1));
      setTimeout(() => {
        updateHeightScrollMenu();
      }, 200);
    }
  }, []);

  const onOpenChange = (event) => {
    const { key } = event;
    setSelectedKey(key);
    // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const [, path] = (key || '').split('__');
    if (path) {
      if (path.includes('http')) {
        window.open(path);
      } else {
        navigate(path);
      }
    }
  };

  return (
    <Menu
      theme={sidebarColorSet.mode}
      mode='inline'
      forceSubMenuRender
      items={getRouteMenus(userPermissions)}
      className={clsx('app-main-sidebar-menu ', {
        'bg-color-menu':
          sidebarColorSet.sidebarBgColor !==
          defaultConfig.sidebar.colorSet.sidebarBgColor,
        'menu-rounded': menuStyle === MenuStyle.ROUNDED,
        'menu-rounded curved-menu': menuStyle === MenuStyle.CURVED_MENU,
        'menu-rounded rounded-menu-reverse':
          menuStyle === MenuStyle.ROUNDED_REVERSE,
        'menu-rounded standard-menu': menuStyle === MenuStyle.STANDARD,
      })}
      openKeys={openKeys}
      onOpenChange={(openKeys) => {
        setOpenKeys(openKeys);
        updateHeightScrollMenu();
      }}
      selectedKeys={selectedKey}
      onClick={onOpenChange}></Menu>
  );
};

export default AppVerticalNav;
