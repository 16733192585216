import routesConfig from '../../pages/routeConfig';
import { isEmpty } from 'src/shared/utils/Typeof';
import { useIntl } from 'react-intl';
import {
  GROUP_TYPE_PROHIBIT_POSITIONS_DOCUMENT,
  GROUP_TYPE_RELATE_DOCUMENT,
  GROUP_TYPE_VERDICT,
} from 'src/shared/constants/DataFixed';

export const GROUP_TYPES = [
  { label: 'Bản án', value: GROUP_TYPE_VERDICT },
  { label: 'Quyết định', value: GROUP_TYPE_RELATE_DOCUMENT },
  {
    label: 'Cấm đảm nhiệm chức vụ',
    value: GROUP_TYPE_PROHIBIT_POSITIONS_DOCUMENT,
  },
];

export const TYPE_IDENTIFICATION = [
  { label: 'CMT', value: 'cmt' },
  { label: 'CCCD', value: 'cccd' },
];

export const TYPE_LLTP = [
  { label: 'Mẫu phiếu LLTP số 1', value: 1 },
  { label: 'Mẫu phiếu LLTP số 2', value: 2 },
];

export const TELE_CODE_NATIONAL = [
  { label: 'Việt Nam', value: '+84' },
  { label: 'Trung Quốc', value: '+86' },
  { label: 'Thái Lan', value: '+66' },
  { label: 'Lào', value: '+856' },
];

export const useBuildMenuToOption = (menu, pagePermission) => {
  const { messages } = useIntl();

  const options = [];
  menu.forEach((route) => {
    if (route.type === 'item') {
      const routeId = route.id;
      const permissions = pagePermission
        .filter((permission) => permission.name.indexOf(`${routeId}.`) === 0)
        .map((permission) => ({
          id: permission?.id,
          name: permission.name.split('.')?.[1],
        }))
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      options.push({
        label: messages[route.messageId],
        value: route.id,
        isPageHidden: route.isPageHidden,
        id: route.id,
        permissions: permissions,
      });
    }

    if (route.type === 'collapse' || route.type === 'group') {
      const collapseOptions = {
        id: route.id,
        label: messages[route.messageId],
        options: [],
      };
      if (route.children) {
        const childrenOps = useBuildMenuToOption(
          route.children,
          pagePermission,
        );
        collapseOptions.options = childrenOps.filter((op) =>
          isEmpty(op?.options),
        );
        options.push(collapseOptions);
        options.push(...childrenOps.filter((op) => !isEmpty(op?.options)));
      }
    }
  });

  return options;
};

export const DEFAULT_PERMISSION_SELECT = (pagePermission = []) => {
  return useBuildMenuToOption(routesConfig, pagePermission);
};

export const getPageNameByPathname = (pathname, routers = routesConfig) => {
  let pageName = '';
  routers.forEach((route) => {
    if (!pageName) {
      if (route.type === 'item') {
        if (route?.path === pathname) {
          pageName = route?.id;
        }
      } else if (route.type === 'collapse' || route.type === 'group') {
        pageName = getPageNameByPathname(pathname, route.children || []);
      }
    }
  });
  return pageName;
};

export const LOCATION_STATE = {
  region: 'location.regionHome',
  country: 'location.countryHome',
  city: 'location.cityHome',
};

export const ambassadorRoleIdDA = 17;
export const ambassadorRoleIdTinh = 23;
export const ambassadorRoleIdVung = 24;
export const ambassadorRoleIdCDT = 18;

export const ambassadorNameDA = 'project';
export const ambassadorNameTinh = 'province';
export const ambassadorNameVung = 'area';
export const ambassadorNameCDT = 'represent';

export const AMBASSADOR_LEVEL = [
  {
    value: ambassadorNameVung,
    label: 'Khu vực',
    roleId: ambassadorRoleIdVung,
    rank: 1,
  },
  {
    value: ambassadorNameTinh,
    label: 'Tỉnh',
    roleId: ambassadorRoleIdTinh,
    rank: 2,
  },
  {
    value: ambassadorNameDA,
    label: 'Dự án',
    roleId: ambassadorRoleIdDA,
    rank: 3,
  },
  {
    value: ambassadorNameCDT,
    label: 'Đại diện chủ đầu tư',
    roleId: ambassadorRoleIdCDT,
    rank: 4,
  },
];

export const LOCATION_SELECT = [
  {
    label: LOCATION_STATE['country'],
    value: 'country',
  },
  {
    label: LOCATION_STATE['city'],
    value: 'city',
  },
  {
    label: LOCATION_STATE['region'],
    value: 'region',
  },
];

export const MOMO_CODES = [
  {
    code: '0',
    description: 'Thành công',
  },
  {
    code: '9000',
    description: 'Giao dịch đã được xác nhận thành công',
  },
  {
    code: '8000',
    description:
      'Giao dịch đang ở trạng thái cần được người dùng xác nhận thanh toán lại',
  },
  {
    code: '7000',
    description: 'Giao dịch đang được xử lý',
  },
  {
    code: '7002',
    description:
      'Giao dịch đang được xử lý bởi nhà cung cấp loại hình thanh toán',
  },
  {
    code: '1000',
    description:
      'Giao dịch đã được khởi tạo, chờ người dùng xác nhận thanh toán',
  },
  {
    code: '11',
    description: 'Truy cập bị từ chối',
  },
  {
    code: '12',
    description: 'Phiên bản API không được hỗ trợ cho yêu cầu này',
  },
  {
    code: '13',
    description: 'Xác thực doanh nghiệp thất bại',
  },
  {
    code: '20',
    description: 'Yêu cầu sai định dạng',
  },
  {
    code: '22',
    description: 'Số tiền giao dịch không hợp lệ',
  },
  {
    code: '40',
    description: 'RequestId bị trùng',
  },
  {
    code: '41',
    description: 'OrderId bị trùng',
  },
  {
    code: '42',
    description: 'OrderId không hợp lệ hoặc không được tìm thấy',
  },
  {
    code: '43',
    description:
      'Yêu cầu bị từ chối vì xung đột trong quá trình xử lý giao dịch',
  },
  {
    code: '44',
    description: 'Giao dịch bị từ chối vì mã thanh toán không hợp lệ',
  },
  {
    code: '1001',
    description:
      'Giao dịch thanh toán thất bại do tài khoản người dùng không đủ tiền',
  },
  {
    code: '1002',
    description: 'Giao dịch bị từ chối do nhà phát hành tài khoản thanh toán',
  },
  {
    code: '1003',
    description: 'Giao dịch bị đã bị hủy',
  },
  {
    code: '1004',
    description:
      'Giao dịch thất bại do số tiền thanh toán vượt quá hạn mức thanh toán của người dùng',
  },
  {
    code: '1005',
    description: 'Giao dịch thất bại do url hoặc QR code đã hết hạn',
  },
  {
    code: '1006',
    description:
      'Giao dịch thất bại do người dùng đã từ chối xác nhận thanh toán',
  },
  {
    code: '1007',
    description:
      'Giao dịch bị từ chối vì tài khoản không tồn tại hoặc đang ở trạng thái ngưng hoạt động',
  },
  {
    code: '1026',
    description: 'Giao dịch bị hạn chế theo thể lệ chương trình khuyến mãi',
  },
  {
    code: '1030',
    description: 'Đơn hàng thanh toán thất bại do thông tin không hợp lệ',
  },
  {
    code: '1080',
    description:
      'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu không được tìm thấy',
  },
  {
    code: '1081',
    description:
      'Giao dịch hoàn tiền bị từ chối. Giao dịch thanh toán ban đầu có thể đã được hoàn',
  },
  {
    code: '2001',
    description: 'Giao dịch thất bại do sai thông tin liên kết',
  },
  {
    code: '2007',
    description: 'Giao dịch thất bại do liên kết hiện đang bị tạm khóa',
  },
  {
    code: '3001',
    description: 'Liên kết thất bại do người dùng từ chối xác nhận',
  },
  {
    code: '3002',
    description: 'Liên kết bị từ chối do không thỏa quy tắc liên kết',
  },
  {
    code: '3003',
    description: 'Hủy liên kết bị từ chối do đã vượt quá số lần hủy',
  },
  {
    code: '3004',
    description: 'Liên kết này không thể hủy do có giao dịch đang chờ xử lý',
  },
  {
    code: '4001',
    description:
      'Giao dịch bị hạn chế do người dùng chưa hoàn tất xác thực tài khoản',
  },
  {
    code: '4010',
    description: 'Quá trình xác minh OTP thất bại',
  },
  {
    code: '4011',
    description: 'OTP chưa được gửi hoặc hết hạn',
  },
  {
    code: '4100',
    description: 'Giao dịch thất bại do người dùng không đăng nhập thành công',
  },
  {
    code: '4015',
    description: 'Quá trình xác minh 3DS thất bại',
  },
  {
    code: '10',
    description: 'Hệ thống đang được bảo trì',
  },
  {
    code: '99',
    description: 'Lỗi không xác định',
  },
];

export const DATA_SOURCE_FORMAT_TYPE = [
  { label: 'csv', value: 'csv' },
  { label: 'json', value: 'json' },
  { label: 'excel', value: 'excel' },
];

export const DATA_SOURCE_STORAGE_PROVIDE = [
  { label: 'HTTPS: Public Web', value: 'HTTPS' },
  {
    label: 'SFTP: Secure File Transfer Protocol',
    value: 'SFTP',
  },
];

export const SCHEDULE_TYPES = [
  { label: 'Scheduled', value: 'basic' },
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Cron',
    value: 'cron',
  },
];

export const REPLICATION_FREQUENCY = [
  {
    label: 'Mỗi giờ',
    value: 1,
  },
  {
    label: '2 giờ 1 lần',
    value: 2,
  },
  {
    label: '3 giờ 1 lần',
    value: 3,
  },
  {
    label: '6 giờ 1 lần',
    value: 6,
  },
  {
    label: '8 giờ 1 lần',
    value: 8,
  },
  {
    label: '12 giờ 1 lần',
    value: 12,
  },
  {
    label: 'Mỗi ngày',
    value: 24,
  },
];

export const DETECT_PROPAGATE_SCHEMA = [
  { label: 'Propagate field changes only', value: 'propagate_columns' },
  { label: 'Propagate all field and stream changes', value: 'propagate_fully' },
  { label: 'Detect changes and manually approve', value: 'ignore' },
  { label: 'Detect changes and pause connection', value: 'disable' },
];
