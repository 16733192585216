import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import style from './ViewSyncStatus.module.scss';
import clsx from 'clsx';
import useFetch from 'src/@crema/hook/fetchData/useFetch';
import API from 'src/@crema/services/apis';
import AppPageMetadata from 'src/@crema/core/AppPageMetadata';
import IntlMessages from 'src/@crema/utility/IntlMessages';
import SubHeaderApp from 'src/@crema/component/SubHeaderApp';
import SubHeaderAppTemplate from 'src/@crema/component/SubHeaderApp/SubHeaderAppTemplate';
import { useIntl } from 'react-intl';
import { METHOD_FETCH } from 'src/shared/constants/FetchData';

import PersonalInfo from './Components/PersonalInfo';
import VerdictInfo from './Components/VerdictInfo';
import BannedHoldingOffice from './Components/BannedHoldingOffice';
import SyncCitizen from './Components/SyncCitizen';
import SyncError from './Components/SyncError';
import { Skeleton, Space } from 'antd';
import { getLocalData } from 'src/@crema/services/Application/LocalStorage';
import AppCard from 'src/@crema/core/AppCard';
import AntButton from 'src/@crema/component/AntButton';
import { CloudSyncOutlined } from '@ant-design/icons';
import { instanceCoreApi } from 'src/@crema/services/setupAxios';
import notification from 'src/shared/utils/notification';

function ViewSyncStatus() {
  const { messages } = useIntl();
  const { id: lltpId } = useParams();
  const [isReSync, setIsReSync] = useState(false);
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [isSyncError, setIsSyncError] = useState(false);

  const client_id = getLocalData('client_id');

  // call API details sync
  const {
    data: dataSyncStatus,
    isLoading: isLoadingSyncStatus,
    fetchData: reloadFetchData,
  } = useFetch(
    {
      url: API.GET_VIEW_SYNC_STATUS_JUDICIAL(Number.parseInt(lltpId)),
      method: METHOD_FETCH.GET,
    },
    [lltpId],
  );

  const resultDataSyncStatus = dataSyncStatus?.result;
  const checkSyncErrorPersonalInfo = resultDataSyncStatus?.status || '';

  const onGoBack = () => {
    navigate(-1);
  };

  //  call api re-sync
  const handleSyncMinistryRetry = (data) => {
    return instanceCoreApi
      .post(API.LOGIN_BOT_SYNC_JUDICIAL(data.client), {})
      .then(() => {
        instanceCoreApi
          .post(API.SYNC_DATA_JUDICIAL_RECORD_RETRY(data.id, data.client), {})
          .then((resSync) => {
            if (resSync?.data?.code === 400) {
              notification.error(
                resSync?.data?.result ||
                  resSync?.data?.message ||
                  'Đồng bộ lại thất bại',
              );
              setIsSyncError(false);
              setIsLoading(false);
            } else {
              notification.success(
                resSync?.data?.result ||
                  resSync?.data?.message ||
                  'Đồng bộ lại thành công',
              );
              setIsSyncError(false);
              setIsLoading(false);
              setIsReSync(false);
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            }
          })
          .catch(() => {
            notification.error('Đồng bộ thất bại !');
            setIsLoading(false);
          });
      })
      .catch(() => {
        notification.error('Đăng nhập bot thất bại !:');
      });
  };

  // 15s reload call api details
  useEffect(() => {
    const idInterval = setInterval(() => {
      reloadFetchData();
    }, 15000);
    () => {
      clearInterval(idInterval);
    };
  }, []);

  return (
    <div>
      <AppPageMetadata title={messages['judicial.viewSyncStatus']} />
      {/*sub header*/}
      <SubHeaderApp>
        <SubHeaderAppTemplate
          title={<IntlMessages id={'judicial.viewSyncStatus'} />}
          isShowGoBack
        />
      </SubHeaderApp>

      {/*content*/}
      <div className={clsx(style.wrapContentSyncViewStatus, 'min-h-100')}>
        {/*sync citizen */}
        <SyncCitizen
          isLoadingSyncStatus={isLoadingSyncStatus}
          resultDataSyncStatus={resultDataSyncStatus}
          checkSyncErrorPersonalInfo={checkSyncErrorPersonalInfo}
        />

        {/*Thông tin nhân thân*/}
        <PersonalInfo
          isLoadingSyncStatus={isLoadingSyncStatus}
          resultDataSyncStatus={resultDataSyncStatus}
        />

        {/* thong tin ban an */}
        <VerdictInfo
          isLoadingSyncStatus={isLoadingSyncStatus}
          resultDataSyncStatus={resultDataSyncStatus}
        />

        {/*  Thông tin Cấm đảm nhiệm chức vụ*/}
        <BannedHoldingOffice
          isLoadingSyncStatus={isLoadingSyncStatus}
          resultDataSyncStatus={resultDataSyncStatus}
        />

        {/* sync error*/}
        {isLoadingSyncStatus || !client_id ? (
          <Skeleton active />
        ) : (
          <SyncError
            resultDataSyncStatus={resultDataSyncStatus}
            client_id={client_id}
            setIsReSync={setIsReSync}
            setIsLoading={setIsLoading}
            isSyncError={isSyncError}
            setIsSyncError={setIsSyncError}
            loading={loading}
            handleSyncMinistryRetry={handleSyncMinistryRetry}
          />
        )}
      </div>

      {/* close view status sync*/}
      <AppCard className={'sticky-footer z-index-20 text-right'}>
        <Space>
          {!isReSync ? (
            <AntButton onClick={onGoBack}>
              {messages['dialog.button.close']}
            </AntButton>
          ) : (
            <AntButton
              onClick={() => {
                setIsLoading(true);
                handleSyncMinistryRetry({
                  id: resultDataSyncStatus?.citizen_profile_id,
                  client: client_id ? client_id : 'stp.mhdigital.vn',
                });
              }}
              type='primary'
              loading={loading}
              icon={<CloudSyncOutlined />}>
              Đồng bộ lại
            </AntButton>
          )}
        </Space>
      </AppCard>
    </div>
  );
}

export default memo(ViewSyncStatus);
