import { getValueByKey, parse } from 'src/shared/utils/String';
import { isArray, isObject } from 'src/shared/utils/Typeof';

export const RenderFieldRawContent = ({ rawText, field }) => {
  const data = parse(rawText);
  if (!data || !field) return '';
  if (isObject(data)) {
    return getValueByKey(data, field);
  }
  if (isArray(data)) {
    return data
      .map((obj) => obj[field])
      .filter(Boolean)
      .join(', ');
  }
};
RenderFieldRawContent.propTypes = {};

RenderFieldRawContent.defaultProps = {};
