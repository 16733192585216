export const ITEM_PERMISSIONS = {
  ACTION: 'action',
  ADD: 'add',
  DELETE: 'delete',
  SETTING: 'setting',
  UPDATE: 'edit',
  HIDDEN: 'update-status',
  EXPORT: 'export',
  REVIEW: 'review',
  VIEW: 'view',
  PAUSE: 'pause',
  STOP: 'stop',
  APPROVE: 'approve',
  VERIFY: 'verify',
  REPORT: 'report',
  APPROVE_ALL: 'approve-all',
  RUNNING: 'running',
  LOCK: 'lock',
  FORCE_UPDATE: 'force-update',
  SYNC: 'sync',
  BLOCK: 'block',
  LOGOUT: 'logout',
};
