import React from 'react';
import AppCard from 'src/@crema/core/AppCard';
import { useNavigate } from 'react-router-dom';
import './index.style.less';
import { SlackOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { isURL } from 'src/shared/utils/URL';

export const WorkspaceItem = ({ item, isLoading }) => {
  const nav = useNavigate();

  const onNavigate = (path) => {
    if (!isURL(path)) {
      nav(path);
      return;
    }
    window.open(path);
    return;
  };

  if (isLoading)
    return (
      <div className={'d-flex flex-col gap-2 pointer'}>
        <Skeleton.Button
          active
          className={'d-flex items-center justify-center workspaceItem w-full'}>
          <div className={'workspaceIcon'}></div>
        </Skeleton.Button>
        <Skeleton.Input className={'workspaceLabel'} size={'small'} active />
      </div>
    );
  return (
    <div
      className={'d-flex flex-col gap-2 pointer'}
      onClick={() => onNavigate(item?.path)}>
      <AppCard className={'d-flex items-center justify-center workspaceItem'}>
        <div className={'workspaceIcon'}>{item?.icon || <SlackOutlined />}</div>
      </AppCard>
      <span className={'workspaceLabel'}>{item?.label}</span>
    </div>
  );
};
