import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const AppSearch = ({ isWorkspace, ...rest }) => {
  return (
    <Tooltip title={'Tìm kiếm'}>
      <SearchOutlined
        style={{
          fontSize: 20,
          marginRight: isWorkspace ? 12 : 0,
          color: isWorkspace ? '#FFFFFF' : '#000000',
        }}
        onClick={() => {}}
        {...rest}
      />
    </Tooltip>
  );
};
