const routes = {
  region: '/region',
  project: '/project',
  detailProject: '/project',
  historyProject: '/history-project',
  approveProject: '/approve-project',
  investor: '/investor',
  profile: '/my-profile',
  profileByTab: (tab) => `/my-profile?tab=${tab}`,

  projectList: '/project/list',
  reportEditProject: '/report-edit-project',
  projectChange: '/project/change',
  detailProjectChange: '/project/change',
  editHistoryProject: '/project/change/edit',
  detailOriginProject: '/project/detail',

  ambassadorList: '/ambassador/list',

  order: '/order',
  orderMomo: '/order-momo',

  userManage: '/user-manage/accounts',
  contactManage: '/user-manage/contacts',

  reportList: '/report-manage/list',
  detailReport: '/report-manage/list',
  addReportList: '/report-manage/list/add',
  reportPackage: '/report-manage/package',
  reportContent: '/report-manage/content',

  reportDownload: '/report-download-management',

  businessPartner: '/business-partner',

  qa: '/qa/list',
  qa_category: '/qa/category/list',
  news: '/news',
  adminRole: '/admin/role',
  adminPermission: '/admin/permission',

  organization: '/organization',
  DetailOrganization: '/detail-organization/:id',
  subsetOrganization: '/subset-organization',
  citizen_list: '/citizen/list',

  verdict: '/verdict',

  documentType: '/document/document-type',
  rawDocument: '/document/raw-document',
  documentJudgment: '/document/document-judgment',
  detailRawDocument: (id) => `/document/raw-document/detail/${id}`,

  judicialListRecord: '/judicial/list-record',
  judicialCreateRecord: '/judicial/create-record',
  judicialCreateRecordAdd: '/judicial/create-record/add',
  judicialRecordEdit: (id) => `/judicial/create-record/edit/${id}`,
  detailJudicialRecord: (status, id) =>
    `/judicial/create-record/${status}/${id}`,
  judicialRecordVerify: '/judicial/verify-record',
  judicialRecordVerifyDetail: '/judicial/verify-record/:id',
  judicialViewSyncStatus: (id) => `/judicial/view-sync-status/${id}`,

  //Record management
  recordManagement: '/record-management',
  policeRecord: '/record-management/police-record',
  courtRecord: '/record-management/court-record',
  justiceDepartmentRecord: '/record-management/justice-lgsp-record',
  judgmentExecutionRecord: '/record-management/judgment-execution-record',
  controlInstituteRecord: '/record-management/control-institute-record',

  // Quản lý dữ liệu chờ số hóa
  digitizedData: '/digitized-data',
  pushConnectApi: '/digitized-data/push-connect-api',
  pushConnectApiDetail: (id) => `/digitized-data/push-connect-api/${id}`,
  pushDataToDigitizationPlatform:
    '/digitized-data/push-data-to-digitized-platform',
  receiveConnectApi: '/digitized-data/receive-connect-api',
  receiveConnectApiDetail: (id) => `/digitized-data/receive-connect-api/${id}`,
  receiveDataToDigitizationPlatform:
    '/digitized-data/receive-data-to-digitized-platform',
  extractedDigitizedDocument: '/digitized-data/extracted-digitized-document',

  //Quản lý nguồn dữ liệu
  configDataProcess: '/management-data-source/config-data-process',
  listSourceData: '/management-data-source/list-source-data',
  configApiProcess: '/management-data-source/config-api-process',
  listSourceApi: '/management-data-source/list-source-api',
  detailInfoData: (id) => `/detail-info-data/${id}`,
  detailSourceData: (id) => `/detail-source-data/${id}`,

  systemSetting: '/system-setting',
  formSetting: '/form-setting-all',
  formSettingVerdict: '/form-setting-verdict',
  formSettingPolice: '/form-setting-police',
  formSettingCourt: '/form-setting-court',
  formSettingProcuracy: '/form-setting-procuracy',
  formSettingExecutionJudgment: '/form-setting-execution-judgment',
  formSettingDecisionJudiciary: '/form-setting-decision-judiciary',
  listWaitingDigital: '/list-waiting-digital',
  listDocumentWaitingConfirm: '/list-document-waiting-confirm',
  serviceParamsConfig: '/service-params-config',

  userActionLog: '/system-log/user-action-log',
  accessLog: '/system-log/access-log',
  settingLog: '/system-log/setting-log',
  loginStatus: '/system-log/login-status',

  notifications: '/notifications',

  helpCenter: '/help',
  listQA: '/help/qa',

  usageGuideManage: '/usage-guide/manage',
  usageGuideView: '/usage-guide/view',

  shareApiConfig: '/share-api-config/all',
  shareApiDistrict: '/share-api-config/district',
  shareApiProvince: '/share-api-config/province',
  shareApiDepartment: '/share-api-config/department',
  shareApiLgsp: '/share-api-config/lgsp',
  shareApiCity: '/share-api-config/city',
  shareApiProvinceInformation: '/share-api-config/province-information-portal',
  shareApiProcurement: '/share-api-config/procurement',
  shareApiCourt: '/share-api-config/court',
  shareApiTha: '/share-api-config/tha',
  shareApiExeJudgment: '/share-api-config/execution-judgment',
  shareApiPolice: '/share-api-config/police',

  searchJudicialRecords: '/backend-data-warehouse/judicial-records',
  searchJudicialRecordsByIdCard:
    '/backend-data-warehouse/judicial-records/by-id-card',
  searchJudicialRecordsByName:
    '/backend-data-warehouse/judicial-records/by-name',
  detailJudicialRecordBDW: (id) =>
    `/backend-data-warehouse/judicial-records/${id}`,

  workspace: '/workspace',

  // bao cao va khai thac du lieu
  synthesisReport: '/synthesis-report',
  detailSynthesisReport: (id) => `/detail-synthesis-report/${id}`,

  systemMonitoringReport: '/system_monitoring_report',
};

export default routes;
