import { isArray, isEmpty, isObject, isString } from 'src/shared/utils/Typeof';
import { KEY_EMPTY_SELECT } from 'src/shared/constants/Default';
import { toPairs } from 'lodash';

export const handleRedundantData = (value, key = '') => {
  if (isArray(value)) {
    return value
      .map((item) => {
        return handleRedundantData(item);
      })
      .filter((f) => !isEmpty(f));
  } else if (isObject(value)) {
    toPairs(value).forEach(([keyItem, valueItem]) => {
      value[keyItem] = handleRedundantData(valueItem, keyItem);
    });
    return value;
  }
  // value empty mặc định select
  if (isString(value) && value?.includes(KEY_EMPTY_SELECT)) {
    return value;
  }
  if (key && key.toLowerCase().includes('password')) {
    // password k trim
    return value;
  }

  if (isString(value)) {
    const valueStr = value.trim();
    if (isEmpty(valueStr)) return undefined;
    return valueStr;
  }
  return value;
};

export const convertDataConnectionUpdate = (data, connectionId) => {
  const scheduleType = data?.schedule_type;
  const scheduleData = {};

  if (scheduleType === 'basic') {
    scheduleData.basic_schedule = {
      units: data?.replication_frequency,
      timeUnit: 'hours',
    };
  }
  if (scheduleType === 'cron') {
    scheduleData.cron = {
      cron_time_zone: 'Asia/Ho_Chi_Minh',
      cron_expression: data?.cron_expression,
    };
  }

  return {
    connection_id: connectionId,
    source_id: data?.source?.source_id,
    destination_id: data?.destination?.destination_id,
    name: data?.name,
    schedule_type: data?.schedule_type,
    schedule_data: scheduleData,
    namespace_definition: 'destination',
    prefix: '',
    non_breaking_changes_preference: data?.non_breaking_changes_preference,
    geography: 'auto',
    sync_catalog: undefined,
    status: 'active',
  };
};
