import React, { useCallback, useState } from 'react';
import { AutoComplete, Divider, Dropdown, Input, Space, Spin } from 'antd';
import { CaretDownOutlined, FilterOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import AntButton from 'src/@crema/component/AntButton';
import IntlMessages from 'src/@crema/utility/IntlMessages';
import useFetch from 'src/@crema/hook/fetchData/useFetch';
import API from 'src/@crema/services/apis';
import { METHOD_FETCH } from 'src/shared/constants/FetchData';
import { FILTER_OPERATION } from 'src/shared/constants/DataTable';
import { useNavigate } from 'react-router-dom';
import config from 'src/config';
import AppEmptyResult from 'src/@crema/core/AppEmptyResult';
import { LabelSearchRawDocument } from 'src/pageComponents/workspace/LabelSearchRawDocument';
import { LabelSearchJudicialRecord } from 'src/pageComponents/workspace/LabelSearchJudicialRecord';

const KEY = {
  TEXT: 'common.searchTextDocument',
  RECORD: 'common.searchRecord',
};
const items = [
  {
    key: KEY.TEXT,
    label: <IntlMessages id={KEY.TEXT} />,
  },
  {
    key: KEY.RECORD,
    label: <IntlMessages id={KEY.RECORD} />,
  },
];
const renderPayload = (filterName, searchValue) => {
  if (filterName === KEY.TEXT)
    return {
      filters: [
        {
          name: 'document_number',
          operation: FILTER_OPERATION.LIKE_IGNORE_CASE,
          value: searchValue,
        },
        {
          name: 'state',
          operation: FILTER_OPERATION.IN,
          value: ['verified', 'done'],
        },
      ],
      pageable: {
        page: 1,
        page_size: 15,
      },
    };
  return {
    filters: [],
    keyword: searchValue,
    pageable: {
      page: 1,
      page_size: 15,
    },
  };
};

export const WorkspaceSearch = ({ openPopup, setOpenPopup }) => {
  const { messages } = useIntl();
  const nav = useNavigate();

  const [filterName, setFilterName] = useState(KEY.TEXT);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  const { data, isLoading } = useFetch(
    {
      url:
        filterName === KEY.TEXT
          ? API.SELECT_RAW_DOCUMENT
          : API.SEARCH_LIST_JUDICIAL_RECORD,
      method: METHOD_FETCH.POST,
      body: renderPayload(filterName, searchValue),
    },
    [filterName, searchValue],
  );

  const onSelectDropdown = ({ key }) => {
    setFilterName(key);
  };

  const onChangeInput = (data) => {
    setInputValue(data);
  };
  const renderOptions = useCallback(
    (data) => {
      if (filterName === KEY.TEXT)
        return data?.map((item) => ({
          label: <LabelSearchRawDocument item={item} />,
          value: String(item?.id),
        }));
      return data?.map((item) => ({
        label: <LabelSearchJudicialRecord item={item} />,
        value: String(item?.citizen_profile_request_id),
      }));
    },
    [filterName],
  );
  const onSelectResult = (id) => {
    if (filterName === KEY.TEXT)
      return nav(`${config.routes.rawDocument}/${id}`);
    return nav(config.routes.detailJudicialRecordBDW(id));
  };
  return (
    <Space
      split={<Divider type={'vertical'} />}
      className={'workspaceSearchContainer'}>
      <Dropdown
        trigger={['click']}
        menu={{
          items,
          onClick: onSelectDropdown,
        }}>
        <Space
          style={{ minWidth: 180 }}
          size={'large'}
          className={'pointer justify-between'}
          onClick={(e) => e.preventDefault()}>
          <Space className={'whitespace-nowrap'}>
            <FilterOutlined />
            <IntlMessages id={filterName} />
          </Space>
          <CaretDownOutlined />
        </Space>
      </Dropdown>
      <AutoComplete
        onClick={(e) => e.stopPropagation()}
        getPopupContainer={(trigger) => trigger.parentElement.parentElement}
        options={renderOptions(data?.result?.items || [])}
        value={inputValue}
        open={openPopup}
        notFoundContent={
          <AppEmptyResult
            title={
              isLoading ? (
                <Spin spinning className={'text-center'} />
              ) : (
                'Không có kết quả'
              )
            }
          />
        }
        onSelect={(value) => onSelectResult(value)}
        popupClassName={'w-full l-0'}
        onChange={onChangeInput}>
        <Input
          bordered={false}
          style={{ height: 48, width: 350 }}
          placeholder={messages['common.typeSearchContent']}
        />
      </AutoComplete>
      <AntButton
        type={'primary'}
        className={'workspaceSearchButton'}
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopup(true);
          setSearchValue(inputValue);
        }}>
        {messages['common.search']}
      </AntButton>
    </Space>
  );
};
