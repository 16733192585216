export const THA_ID = 10;
export const VKS_ID = 9;
export const CA_ID = 8;
export const STP_ID = 1;

export const TA_ID = 12;

export const GROUP_TYPE_VERDICT = 'verdict';
export const GROUP_TYPE_RELATE_DOCUMENT = 'relate_document';
export const GROUP_TYPE_PROHIBIT_POSITIONS_DOCUMENT =
  'prohibit_positions_document';
export const WORKSPACE_ID = '07f2fe54-745f-4167-937a-ac364731054c';
export const SOURCE_DEFINITION_FILE_ID = '778daa7c-feaf-4db6-96f3-70fd645acc77';
export const SOURCE_DEFINITION_FILE_NAME =
  'File (CSV, JSON, Excel, Feather, Parquet)';
export const SOURCE_DEFINITION_API_ID = 'bfe1f2a1-a0fe-4695-8ee5-c4c76b1a77c0';
export const SOURCE_DEFINITION_API_NAME = 'Basic Auth API';
export const DESTINATION_ID = '8ad170f7-07c8-44b7-9cad-b50502bebdd2';

export const LOGS_FAKE = {
  logLines: [
    '2024-02-15 09:15:42 \u001b[46mplatform\u001b[0m > Docker volume job log path: /tmp/workspace/137/0/logs.log',
    '2024-02-15 09:15:42 \u001b[46mplatform\u001b[0m > Executing worker wrapper. Airbyte version: 0.50.45',
    '2024-02-15 09:15:42 \u001b[46mplatform\u001b[0m > Attempt 0 to save workflow id for cancellation',
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > ',
    "2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Using default value for environment variable SIDECAR_KUBE_CPU_LIMIT: '2.0'",
    "2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Using default value for environment variable SOCAT_KUBE_CPU_LIMIT: '2.0'",
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > ----- START CHECK -----',
    "2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Using default value for environment variable SIDECAR_KUBE_CPU_REQUEST: '0.1'",
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > ',
    "2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Using default value for environment variable SOCAT_KUBE_CPU_REQUEST: '0.1'",
    "2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Using default value for environment variable LAUNCHDARKLY_KEY: ''",
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Checking if airbyte/source-file:0.3.15 exists...',
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > airbyte/source-file:0.3.15 was found locally.',
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Creating docker container = source-file-check-137-0-kpiqi with resources io.airbyte.config.ResourceRequirements@4e325e26[cpuRequest=,cpuLimit=,memoryRequest=,memoryLimit=,additionalProperties={}] and allowedHosts io.airbyte.config.AllowedHosts@16c444b6[hosts=[*, *.datadoghq.com, *.datadoghq.eu, *.sentry.io],additionalProperties={}]',
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Preparing command: docker run --rm --init -i -w /data/137/0 --log-driver none --name source-file-check-137-0-kpiqi --network host -v airbyte_workspace:/data -v /tmp/airbyte_local:/local -e DEPLOYMENT_MODE=OSS -e WORKER_CONNECTOR_IMAGE=airbyte/source-file:0.3.15 -e AUTO_DETECT_SCHEMA=true -e LAUNCHDARKLY_KEY= -e SOCAT_KUBE_CPU_REQUEST=0.1 -e SOCAT_KUBE_CPU_LIMIT=2.0 -e FIELD_SELECTION_WORKSPACES= -e USE_STREAM_CAPABLE_STATE=true -e AIRBYTE_ROLE=dev -e WORKER_ENVIRONMENT=DOCKER -e APPLY_FIELD_SELECTION=false -e WORKER_JOB_ATTEMPT=0 -e OTEL_COLLECTOR_ENDPOINT=http://host.docker.internal:4317 -e FEATURE_FLAG_CLIENT=config -e AIRBYTE_VERSION=0.50.45 -e WORKER_JOB_ID=137 airbyte/source-file:0.3.15 check --config source_config.json',
    '2024-02-15 09:15:43 \u001b[46mplatform\u001b[0m > Reading messages from protocol version 0.2.0',
    '2024-02-15 09:15:46 \u001b[46mplatform\u001b[0m > TransportParams: None',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Check succeeded',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ----- END CHECK -----',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Docker volume job log path: /tmp/workspace/137/0/logs.log',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Executing worker wrapper. Airbyte version: 0.50.45',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Attempt 0 to save workflow id for cancellation',
    "2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Using default value for environment variable SIDECAR_KUBE_CPU_LIMIT: '2.0'",
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ',
    "2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Using default value for environment variable SOCAT_KUBE_CPU_LIMIT: '2.0'",
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ----- START CHECK -----',
    "2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Using default value for environment variable SIDECAR_KUBE_CPU_REQUEST: '0.1'",
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > ',
    "2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Using default value for environment variable SOCAT_KUBE_CPU_REQUEST: '0.1'",
    "2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Using default value for environment variable LAUNCHDARKLY_KEY: ''",
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Checking if airbyte/destination-s3:0.5.8 exists...',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > airbyte/destination-s3:0.5.8 was found locally.',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Creating docker container = destination-s3-check-137-0-lclmk with resources io.airbyte.config.ResourceRequirements@b3fb9f1[cpuRequest=,cpuLimit=,memoryRequest=,memoryLimit=,additionalProperties={}] and allowedHosts null',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Preparing command: docker run --rm --init -i -w /data/137/0 --log-driver none --name destination-s3-check-137-0-lclmk --network host -v airbyte_workspace:/data -v /tmp/airbyte_local:/local -e DEPLOYMENT_MODE=OSS -e WORKER_CONNECTOR_IMAGE=airbyte/destination-s3:0.5.8 -e AUTO_DETECT_SCHEMA=true -e LAUNCHDARKLY_KEY= -e SOCAT_KUBE_CPU_REQUEST=0.1 -e SOCAT_KUBE_CPU_LIMIT=2.0 -e FIELD_SELECTION_WORKSPACES= -e USE_STREAM_CAPABLE_STATE=true -e AIRBYTE_ROLE=dev -e WORKER_ENVIRONMENT=DOCKER -e APPLY_FIELD_SELECTION=false -e WORKER_JOB_ATTEMPT=0 -e OTEL_COLLECTOR_ENDPOINT=http://host.docker.internal:4317 -e FEATURE_FLAG_CLIENT=config -e AIRBYTE_VERSION=0.50.45 -e WORKER_JOB_ID=137 airbyte/destination-s3:0.5.8 check --config source_config.json',
    '2024-02-15 09:15:47 \u001b[46mplatform\u001b[0m > Reading messages from protocol version 0.2.0',
    '2024-02-15 09:15:49 \u001b[46mplatform\u001b[0m > WARN StatusConsoleListener The use of package scanning to locate plugins is deprecated and will be removed in a future release',
    '2024-02-15 09:15:49 \u001b[46mplatform\u001b[0m > WARN StatusConsoleListener The use of package scanning to locate plugins is deprecated and will be removed in a future release',
    '2024-02-15 09:15:49 \u001b[46mplatform\u001b[0m > WARN StatusConsoleListener The use of package scanning to locate plugins is deprecated and will be removed in a future release',
    '2024-02-15 09:15:49 \u001b[46mplatform\u001b[0m > WARN StatusConsoleListener The use of package scanning to locate plugins is deprecated and will be removed in a future release',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationCliParser(parseOptions):126 - integration args: {check=null, config=source_config.json}',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.a.AdaptiveDestinationRunner$Runner(getDestination):80 - Running destination under deployment mode: OSS',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.a.AdaptiveDestinationRunner$Runner(run):101 - Starting destination: io.airbyte.integrations.destination.s3.S3Destination',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationCliParser(parseOptions):126 - integration args: {check=null, config=source_config.json}',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationRunner(runInternal):132 - Running integration: io.airbyte.integrations.destination.s3.S3Destination',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationRunner(runInternal):133 - Command: CHECK',
    "2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationRunner(runInternal):134 - Integration config: IntegrationConfig{command=CHECK, configPath='source_config.json', catalogPath='null', statePath='null'}",
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[33mWARN\u001b[m i.a.c.i.b.DestinationConfig(initialize):36 - Singleton was already initialized.',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[33mWARN\u001b[m c.n.s.JsonMetaSchema(newValidator):278 - Unknown keyword airbyte_secret - you should define your own Meta Schema. If the keyword is irrelevant for validation, just use a NonValidationKeyword',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[33mWARN\u001b[m c.n.s.JsonMetaSchema(newValidator):278 - Unknown keyword always_show - you should define your own Meta Schema. If the keyword is irrelevant for validation, just use a NonValidationKeyword',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[33mWARN\u001b[m c.n.s.JsonMetaSchema(newValidator):278 - Unknown keyword order - you should define your own Meta Schema. If the keyword is irrelevant for validation, just use a NonValidationKeyword',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[33mWARN\u001b[m c.n.s.JsonMetaSchema(newValidator):278 - Unknown keyword requires - you should define your own Meta Schema. If the keyword is irrelevant for validation, just use a NonValidationKeyword',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.d.s.S3FormatConfigs(getS3FormatConfig):22 - S3 format config: {"flattening":"No flattening","compression":{"compression_type":"No Compression"},"format_type":"JSONL"}',
    '2024-02-15 09:15:50 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:50 \u001b[32mINFO\u001b[m i.a.c.i.d.s.S3DestinationConfig(createS3Client):239 - Creating S3 client...',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:51 \u001b[32mINFO\u001b[m i.a.c.i.d.s.S3BaseChecks(testIAMUserHasListObjectPermission):131 - Started testing if IAM user can call listObjects on the destination bucket',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:51 \u001b[1;31mERROR\u001b[m i.a.c.i.d.s.BaseS3Destination(check):52 - Exception attempting to access the S3 bucket: ',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > com.amazonaws.SdkClientException: Unable to execute HTTP request: Connect to 100.67.127.66:9080 [/100.67.127.66] failed: Connection refused',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.handleRetryableException(AmazonHttpClient.java:1219) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeHelper(AmazonHttpClient.java:1165) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.doExecute(AmazonHttpClient.java:814) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeWithTimer(AmazonHttpClient.java:781) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.execute(AmazonHttpClient.java:755) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.access$500(AmazonHttpClient.java:715) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutionBuilderImpl.execute(AmazonHttpClient.java:697) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient.execute(AmazonHttpClient.java:561) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient.execute(AmazonHttpClient.java:541) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.services.s3.AmazonS3Client.invoke(AmazonS3Client.java:5456) ~[aws-java-sdk-s3-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.services.s3.AmazonS3Client.invoke(AmazonS3Client.java:5403) ~[aws-java-sdk-s3-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.services.s3.AmazonS3Client.invoke(AmazonS3Client.java:5397) ~[aws-java-sdk-s3-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.services.s3.AmazonS3Client.listObjects(AmazonS3Client.java:928) ~[aws-java-sdk-s3-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.cdk.integrations.destination.s3.S3BaseChecks.testIAMUserHasListObjectPermission(S3BaseChecks.java:133) ~[airbyte-cdk-s3-destinations-0.10.2.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.cdk.integrations.destination.s3.BaseS3Destination.check(BaseS3Destination.java:46) [airbyte-cdk-s3-destinations-0.10.2.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.cdk.integrations.base.IntegrationRunner.runInternal(IntegrationRunner.java:153) [airbyte-cdk-core-0.10.2.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.cdk.integrations.base.IntegrationRunner.run(IntegrationRunner.java:125) [airbyte-cdk-core-0.10.2.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.cdk.integrations.base.adaptive.AdaptiveDestinationRunner$Runner.run(AdaptiveDestinationRunner.java:102) [airbyte-cdk-core-0.10.2.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat io.airbyte.integrations.destination.s3.S3DestinationRunner.main(S3DestinationRunner.java:15) [io.airbyte.airbyte-integrations.connectors-destination-s3-0.50.41.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > Caused by: org.apache.http.conn.HttpHostConnectException: Connect to 100.67.127.66:9080 [/100.67.127.66] failed: Connection refused',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.conn.DefaultHttpClientConnectionOperator.connect(DefaultHttpClientConnectionOperator.java:156) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.conn.PoolingHttpClientConnectionManager.connect(PoolingHttpClientConnectionManager.java:376) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.NativeMethodAccessorImpl.invoke0(Native Method) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.NativeMethodAccessorImpl.invoke(NativeMethodAccessorImpl.java:77) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.DelegatingMethodAccessorImpl.invoke(DelegatingMethodAccessorImpl.java:43) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/java.lang.reflect.Method.invoke(Method.java:568) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.conn.ClientConnectionManagerFactory$Handler.invoke(ClientConnectionManagerFactory.java:76) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.conn.$Proxy31.connect(Unknown Source) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.MainClientExec.establishRoute(MainClientExec.java:393) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.MainClientExec.execute(MainClientExec.java:236) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.ProtocolExec.execute(ProtocolExec.java:186) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.InternalHttpClient.doExecute(InternalHttpClient.java:185) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.CloseableHttpClient.execute(CloseableHttpClient.java:83) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.CloseableHttpClient.execute(CloseableHttpClient.java:56) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.apache.client.impl.SdkHttpClient.execute(SdkHttpClient.java:72) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeOneRequest(AmazonHttpClient.java:1346) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeHelper(AmazonHttpClient.java:1157) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \t... 17 more',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > Caused by: java.net.ConnectException: Connection refused',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/sun.nio.ch.Net.pollConnect(Native Method) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/sun.nio.ch.Net.pollConnectNow(Net.java:672) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/sun.nio.ch.NioSocketImpl.timedFinishConnect(NioSocketImpl.java:554) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/sun.nio.ch.NioSocketImpl.connect(NioSocketImpl.java:602) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/java.net.SocksSocketImpl.connect(SocksSocketImpl.java:327) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/java.net.Socket.connect(Socket.java:633) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.conn.socket.PlainConnectionSocketFactory.connectSocket(PlainConnectionSocketFactory.java:75) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.conn.DefaultHttpClientConnectionOperator.connect(DefaultHttpClientConnectionOperator.java:142) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.conn.PoolingHttpClientConnectionManager.connect(PoolingHttpClientConnectionManager.java:376) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.NativeMethodAccessorImpl.invoke0(Native Method) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.NativeMethodAccessorImpl.invoke(NativeMethodAccessorImpl.java:77) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/jdk.internal.reflect.DelegatingMethodAccessorImpl.invoke(DelegatingMethodAccessorImpl.java:43) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat java.base/java.lang.reflect.Method.invoke(Method.java:568) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.conn.ClientConnectionManagerFactory$Handler.invoke(ClientConnectionManagerFactory.java:76) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.conn.$Proxy31.connect(Unknown Source) ~[?:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.MainClientExec.establishRoute(MainClientExec.java:393) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.MainClientExec.execute(MainClientExec.java:236) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.execchain.ProtocolExec.execute(ProtocolExec.java:186) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.InternalHttpClient.doExecute(InternalHttpClient.java:185) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.CloseableHttpClient.execute(CloseableHttpClient.java:83) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat org.apache.http.impl.client.CloseableHttpClient.execute(CloseableHttpClient.java:56) ~[httpclient-4.5.14.jar:4.5.14]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.apache.client.impl.SdkHttpClient.execute(SdkHttpClient.java:72) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeOneRequest(AmazonHttpClient.java:1346) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \tat com.amazonaws.http.AmazonHttpClient$RequestExecutor.executeHelper(AmazonHttpClient.java:1157) ~[aws-java-sdk-core-1.12.267.jar:?]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > \t... 17 more',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:51 \u001b[32mINFO\u001b[m i.a.c.i.b.IntegrationRunner(runInternal):231 - Completed integration: io.airbyte.integrations.destination.s3.S3Destination',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > 2024-02-15 09:15:51 \u001b[32mINFO\u001b[m i.a.c.i.b.a.AdaptiveDestinationRunner$Runner(run):103 - Completed destination: io.airbyte.integrations.destination.s3.S3Destination',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > Check connection job received output: io.airbyte.config.StandardCheckConnectionOutput@2e513f7c[status=failed,message=Could not connect to the S3 bucket with the provided configuration. ',
    'Unable to execute HTTP request: Connect to 100.67.127.66:9080 [/100.67.127.66] failed: Connection refused,additionalProperties={}]',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > ',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > ----- END CHECK -----',
    '2024-02-15 09:15:51 \u001b[46mplatform\u001b[0m > ',
    '2024-02-15 09:15:52 \u001b[46mplatform\u001b[0m > Retry State: RetryManager(completeFailureBackoffPolicy=BackoffPolicy(minInterval=PT10S, maxInterval=PT30M, base=3), partialFailureBackoffPolicy=null, successiveCompleteFailureLimit=5, totalCompleteFailureLimit=10, successivePartialFailureLimit=1000, totalPartialFailureLimit=10, successiveCompleteFailures=1, totalCompleteFailures=1, successivePartialFailures=0, totalPartialFailures=0)',
    ' Backoff before next attempt: 10 seconds',
    '2024-02-15 09:15:52 \u001b[46mplatform\u001b[0m > Failing job: 137, reason: Connection Check Failed 60a448f3-db18-4c24-a4c8-76e086ad859b',
  ],
};
