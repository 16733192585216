import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Dropdown, Space } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
import './index.style.less';
import { useThemeContext } from 'src/@crema/utility/AppContextProvider/ThemeContextProvider';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import { useSidebarContext } from 'src/@crema/utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';
import { useAuthSSOContext } from 'src/@crema/utility/AuthSSOContext';
import AntAvatar from 'src/@crema/component/AntAvatar/AntAvatar';
import { CheckOutlined } from '@ant-design/icons';

const OrganizationUnitItem = ({
  fullName,
  role,
  organization_name,
  isActive,
}) => {
  return (
    <div
      className={`d-flex justify-between items-center ${
        isActive ? 'user-profile-dropdown-active' : ''
      }`}
      style={{ minWidth: 200 }}>
      <div className={'d-flex flex-col'}>
        <span style={{ fontWeight: isActive ? 500 : 400 }}>
          {organization_name || 'ADMIN'}
        </span>
        <Space>
          <span style={{ color: '#00000073' }}>{fullName}</span>
          <span
            style={{
              fontSize: 10,
              border: '1px solid #D9D9D9',
              padding: '4px 6px',
              borderRadius: 2,
              color: '#00000073',
            }}>
            {role}
          </span>
        </Space>
      </div>
      {isActive && <CheckOutlined style={{ color: '#1890FF' }} />}
    </div>
  );
};

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  const { signOut } = useAuthSSOContext();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName
        .split(' ')
        ?.map((name) => name?.[0])
        ?.join('')
        .toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0).toUpperCase();
    }
  };

  // const menu = (
  //   <Menu className='dropdown-list'>
  //     <Menu.Item onClick={() => navigate('/my-profile')}>My Profile</Menu.Item>
  //     <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
  //   </Menu>
  // );

  const items = [
    {
      label: (
        <div onClick={() => navigate('/my-profile')}>Thông tin cá nhân</div>
      ),
      key: 'user_info-1',
    },
    {
      label: 'Đơn vị tổ chức',
      key: 'organization',
      children: [
        {
          label: (
            <OrganizationUnitItem
              isActive
              fullName={user?.displayName}
              role={user?.roles?.[0]}
              organization_name={user?.organization_name}
            />
          ),
          key: 'key-1',
        },
      ],
    },
    {
      label: <div onClick={() => signOut()}>Đăng xuất</div>,
      key: 'user_info-2',
    },
  ];

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown relative'
            overlayClassName='dropdown-list'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <AntAvatar
                  className='cr-user-info-avatar'
                  src={user?.photoURL}
                />
              ) : (
                <AntAvatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </AntAvatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                {/*<span className='cr-user-designation text-truncate'>*/}
                {/*  ID: {user?.id}*/}
                {/*</span>*/}
              </span>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlayClassName='dropdown-list'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <AntAvatar
                  className='cr-user-info-avatar'
                  src={user?.photoURL}
                />
              ) : (
                <AntAvatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </AntAvatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                </span>
                {/*<span className='cr-user-designation text-truncate'>*/}
                {/*  ID: {user?.id}*/}
                {/*</span>*/}
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
