import PropTypes from 'prop-types';
import React from 'react';
import './page_breadcrumb.styles.less';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AppIconButton from 'src/@crema/core/AppIconButton';
import { useNavigate } from 'react-router-dom';

const SubHeaderAppTemplate = ({ title, isShowGoBack }) => {
  const navigate = useNavigate();
  return (
    <div className='sub-header-app-template ant-d-flex ant-align-center ant-justify-center'>
      {isShowGoBack && (
        <AppIconButton
          className='mr-1'
          disabled={false}
          title={'Quay lại'}
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <h1
        className='page_breadcrumb__page-title my-0'
        style={{ lineHeight: '28px' }}>
        {title}
      </h1>
    </div>
  );
};

SubHeaderAppTemplate.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
};

SubHeaderAppTemplate.defaultProps = {};

export default SubHeaderAppTemplate;
