import { isEmpty } from 'src/shared/utils/Typeof';

export const checkRouteActive = (pathname, route) => {
  return pathname === route?.path;
};

export const getOpenKeys = (pathname, routes, parentKeys = []) => {
  let rs;
  routes.forEach((route) => {
    if (!isEmpty(rs)) return;
    if (!isEmpty(route.children)) {
      rs = getOpenKeys(pathname, route.children, [...parentKeys, route?.id]);
    } else if (checkRouteActive(pathname, route)) {
      rs = [...parentKeys, `${route?.id}__${route?.path}`];
    }
  });

  return rs;
};
