import React, { useCallback, useMemo, useState } from 'react';
import './index.style.less';
import { HeaderWorkspace } from 'src/pageComponents/workspace/HeaderWorkspace';
import { Col, Divider, Empty, Row } from 'antd';
import { WorkspaceItem } from 'src/pageComponents/workspace/WorkspaceItem';
import { WorkspaceSearch } from 'src/pageComponents/workspace/WorkspaceSearch';
import AppPageMetadata from 'src/@crema/core/AppPageMetadata';
import { isEmpty } from 'src/shared/utils/Typeof';
import { useAuthUser } from 'src/@crema/utility/AuthHooks';
import useFetch from 'src/@crema/hook/fetchData/useFetch';
import API from 'src/@crema/services/apis';
import AntButton from 'src/@crema/component/AntButton';
import { useNavigate } from 'react-router-dom';
import config from 'src/config';
import { KEY_TAB_PROFILE } from 'src/shared/constants/userProfile.constant';
import { useIntl } from 'react-intl';
import { useListPagePermission } from 'src/hooks/useListPagePermission';

const WorkspacePage = () => {
  const { messages } = useIntl();
  const nav = useNavigate();
  const { user } = useAuthUser();
  const { listPageName } = useListPagePermission();
  const { data, isLoading } = useFetch(
    {
      url: API.SEARCH_WORKSPACE_USER(user?.id),
      enabled: !!user?.id,
    },
    [user?.id],
  );

  // Find corresponding object in arr2 based on id
  const mergeArrays = useCallback((arr1, arr2) => {
    return arr1
      .map((item1) => {
        const matchingItem = arr2.find((item2) => item2.id === item1.page_name);
        if (matchingItem) {
          return {
            ...item1,
            ...matchingItem,
          };
        }
        return null; // Handle if no match found
      })
      .filter(Boolean); // Remove any null values
  }, []);

  const listWorkspace = useMemo(() => {
    const dataWorkspace = data?.result || [];
    const arrSort = mergeArrays(dataWorkspace, listPageName).sort(
      (a, b) => a?.index - b?.index,
    );
    return arrSort;
  }, [data?.result, listPageName, mergeArrays]);

  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className={'workspaceContainer'} onClick={() => setOpenPopup(false)}>
      <AppPageMetadata title={'Bàn làm việc'} />
      <HeaderWorkspace />
      <div
        className={
          'd-flex flex-col justify-center items-center workspaceContent container'
        }>
        <div>
          <h1 className={'greetingTitle'}>Xin chào, {user?.displayName}</h1>
        </div>
        <div className={'workspaceSearch'}>
          <WorkspaceSearch openPopup={openPopup} setOpenPopup={setOpenPopup} />
        </div>
        <div className={'workspaceDivider'}>
          <Divider />
        </div>
        <h2 className={'workspaceTitle'}>Bàn làm việc</h2>
        {isEmpty(data?.result) && !isLoading && (
          <>
            <Empty description={'Bạn không có tính năng khả dụng'} />
            <br />
            <AntButton
              onClick={() =>
                nav(config.routes.profileByTab(KEY_TAB_PROFILE.WORKSPACE))
              }>
              {messages['userProfile.workspace']}
            </AntButton>
          </>
        )}
        {isLoading && (
          <Row gutter={[20, 40]} className='pb-10 w-full justify-center'>
            {[...new Array(8)].slice(0, 8).map((item, index) => (
              <Col xl={6} md={8} sm={12} xs={24} key={index}>
                <WorkspaceItem item={item} isLoading={isLoading} />
              </Col>
            ))}
          </Row>
        )}
        {!isEmpty(data?.result) && (
          <Row gutter={[20, 40]} className='pb-10 w-full justify-center'>
            {listWorkspace.slice(0, 8).map((item) => (
              <Col xl={6} md={8} sm={12} xs={24} key={item?.id}>
                <WorkspaceItem item={item} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default WorkspacePage;
