import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { DATE_FORMAT_LIST } from 'src/shared/constants/Date';
import locale from 'src/shared/locale/DatePickerVI';

const AntDateRangePicker = (props) => {
  return (
    <DatePicker.RangePicker
      format={DATE_FORMAT_LIST}
      locale={locale}
      {...props}
    />
  );
};

export default AntDateRangePicker;

AntDateRangePicker.propTypes = {
  pickerType: PropTypes.string,
};
