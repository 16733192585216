export const INPUT_TYPE = {
  TEXT_AREA: 'text_area',
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  SELECT_ASYNC: 'select_async',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  DATE_RANGE: 'date_range',
  FACEBOOK: 'facebook',
  YOUTUBE: 'youtube',
  IMAGE: 'image',
  FILE: 'file',
  LINK_VIDEO: 'link_video',
  LIST_IMAGE: 'list_image',
  LIST_VIDEO: 'list_video',
  LIST_IMAGE_LINK: 'list_image_link',
  BANNER_ADS: 'banner_ads',
};

export const INPUT_TYPE_LABEL = {
  [INPUT_TYPE.TEXT_AREA]: 'Văn bản dài',
  [INPUT_TYPE.TEXT]: 'Văn bản ngắn',
  [INPUT_TYPE.NUMBER]: 'Số',
  [INPUT_TYPE.SELECT]: 'Danh sách',
  [INPUT_TYPE.SELECT_ASYNC]: 'Danh sách chọn từ api',
  [INPUT_TYPE.CHECKBOX]: 'Checkbox ',
  [INPUT_TYPE.DATE]: 'Ngày',
  [INPUT_TYPE.DATE_RANGE]: 'Dải ngày',
  [INPUT_TYPE.IMAGE]: 'Hình ảnh',
  [INPUT_TYPE.LIST_IMAGE]: 'Danh sách hình ảnh',
  [INPUT_TYPE.LIST_IMAGE_LINK]: 'Danh sách hình ảnh và link',
  [INPUT_TYPE.LINK_VIDEO]: 'Link Video',
  [INPUT_TYPE.LIST_VIDEO]: 'Danh sách Video',
  [INPUT_TYPE.FILE]: 'Tệp',
  [INPUT_TYPE.BANNER_ADS]: 'Banner Quảng cáo',
};
