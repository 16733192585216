import React from 'react';
import config from 'src/config/index';
import { ROUTER_NAME } from 'src/pages/routeConfig';
import { KEY_STATUS_CREATE_JUDICIAL_RECORD } from 'src/pages/judicialRecords/createRecordInformation/utils';
import FormSettingVerdict from 'src/pages/formSetting/FormSettingVerdict';
import FormSettingPolice from 'src/pages/formSetting/FormSettingPolice';
import FormSettingCourt from 'src/pages/formSetting/FormSettingCourt';
import FormSettingProcuracy from 'src/pages/formSetting/FormSettingProcuracy';
import FormSettingExecutionJudgment from 'src/pages/formSetting/FormSettingExecutionJudgment';
import FormSettingDecisionJudiciary from 'src/pages/formSetting/FormSettingDecisionJudiciary';
import FormSettingPage from 'src/pages/formSetting';
import { HelpCenterPage } from 'src/pages/help';
import { UsageGuideViewPage } from 'src/pages/usageGuide/view';
import ViewSyncStatus from 'src/pages/judicialRecords/ViewSyncStatus';

const AdminUser = React.lazy(() => import('../Admin/AdminUser'));
const AdminRole = React.lazy(() => import('../Admin/AdminRole'));

const AdminPermissions = React.lazy(() => import('../Admin/AdminPermissions'));

const OrganizationPage = React.lazy(() => import('../organization'));
const DetailOrganizationPage = React.lazy(() =>
  import('../organization/DetailOrganization'),
);

const DocumentTypePage = React.lazy(() => import('../documentType'));
const RawDocumentPage = React.lazy(() => import('../rawDocument'));
const EditRawDocumentPage = React.lazy(() =>
  import('../rawDocument/EditRawDocument'),
);
const DetailRawDocumentPage = React.lazy(() => import('../rawDocument/detail'));
const PoliceRecordPage = React.lazy(() => import('../records/policeRecords'));
const CourtRecordPage = React.lazy(() => import('../records/courtRecords'));
const JudgmentExecutionRecordPage = React.lazy(() =>
  import('../records/judgmentExecutionRecords'),
);
const JusticeDepartmentRecordPage = React.lazy(() =>
  import('../records/justiceDepartmentRecords'),
);
const ControlInstitutionRecordPage = React.lazy(() =>
  import('../records/controlInstitutionRecords'),
);

const SusetOriganizationPage = React.lazy(() =>
  import('../organization/subsetOriganization'),
);

const CreateJudicialRecord = React.lazy(() =>
  import('../judicialRecords/createRecordInformation'),
);
const JudicialRecordAdd = React.lazy(() =>
  import('../judicialRecords/createRecordInformation/add'),
);
const JudicialRecordEdit = React.lazy(() =>
  import('../judicialRecords/createRecordInformation/edit'),
);
const DetailVerifiedCreateRecordPage = React.lazy(() =>
  import('../judicialRecords/createRecordInformation/verified'),
);

const DetailVerifiedLLTPPage = React.lazy(() =>
  import('../judicialRecords/createRecordInformation/VerifiedLLTPInfo'),
);
const DetailPendingCreateRecordPage = React.lazy(() =>
  import('../judicialRecords/createRecordInformation/pending'),
);

const JudicialRecordVerifyPage = React.lazy(() =>
  import('../judicialRecords/verify'),
);
const DetailVerifyJudicialPage = React.lazy(() =>
  import('../judicialRecords/verify/detail'),
);

const PushConnectApiPage = React.lazy(() =>
  import('../digitizedData/pushConnectApi'),
);
const DetailPushConnectApiPage = React.lazy(() =>
  import('../digitizedData/pushConnectApi/detail'),
);
const ReceiveConnectApiPage = React.lazy(() =>
  import('../digitizedData/receiveConnectApi'),
);
const DetailReceiveConnectApiPage = React.lazy(() =>
  import('../digitizedData/receiveConnectApi/detail'),
);

const ExtractedDigitizedDocumentPage = React.lazy(() =>
  import('../digitizedData/extractedDigitizedDocument'),
);

const UserActionLogPage = React.lazy(() =>
  import('../systemLog/userActionLog'),
);
const AccessLogPage = React.lazy(() => import('../systemLog/accessLog'));
const SettingLogPage = React.lazy(() => import('../systemLog/settings'));
const LoginStatusPage = React.lazy(() => import('../systemLog/loginStatus'));

const NotificationPage = React.lazy(() => import('../notifications'));

const ShareApiListPage = React.lazy(() => import('../shareApiConfig'));
const ShareApiDistrictPage = React.lazy(() =>
  import('../shareApiConfig/district'),
);
const ShareApiDepartmentPage = React.lazy(() =>
  import('../shareApiConfig/department'),
);
const ShareApiProvincePage = React.lazy(() =>
  import('../shareApiConfig/province'),
);
const ShareApiLgspPage = React.lazy(() => import('../shareApiConfig/lgsp'));
const ShareApiCityPage = React.lazy(() => import('../shareApiConfig/city'));
const ShareProvincialInformationPortalPage = React.lazy(() =>
  import('../shareApiConfig/provinceInfoPortal'),
);
const ShareApiProcuracyPage = React.lazy(() =>
  import('../shareApiConfig/procuracy'),
);
const ShareApiCourtPage = React.lazy(() => import('../shareApiConfig/court'));
const ShareApiThaPage = React.lazy(() => import('../shareApiConfig/tha'));
const ShareApiPolicePage = React.lazy(() => import('../shareApiConfig/police'));

// const SearchJudicialRecordsPage = React.lazy(() =>
//   import('../backendDataWarehouse/judicialRecords'),
// );
const DetailJudicialRecordBDW = React.lazy(() =>
  import('../backendDataWarehouse/judicialRecords/detail'),
);
const SearchJudicialRecordsByName = React.lazy(() =>
  import('../backendDataWarehouse/judicialRecords/byName'),
);
const SearchJudicialRecordsByIdCard = React.lazy(() =>
  import('../backendDataWarehouse/judicialRecords/byIdCard'),
);

const ListQAPage = React.lazy(() => import('../help/qa'));

const UsageGuideManagePage = React.lazy(() => import('../usageGuide/manage'));
const StreamDataPage = React.lazy(() => import('../streamData'));
const DetailInfoDataPage = React.lazy(() =>
  import('../streamConnection/infoData/detailInfoData'),
);
const SourceDataPage = React.lazy(() => import('../sourceData'));

const StreamApiPage = React.lazy(() => import('../streamAPI'));
const SourceApiPage = React.lazy(() => import('../sourceAPI'));

const ServiceParamsConfigPage = React.lazy(() =>
  import('../serviceParamsConfig'),
);

const SynthesisReport = React.lazy(() => import('../SynthesisReport'));
const DetailSynthesisReport = React.lazy(() =>
  import('../SynthesisReport/DetailSynthesisReport/DetailSynthesisReport'),
);

const ListWaitingDigital = React.lazy(() =>
  import('../ConfigParameterDissection/ListWaitingDigital'),
);
const ListDigitizedDocumentsWaitingConfirm = React.lazy(() =>
  import('../ConfigParameterDissection/ListDigitizedDocumentsWaitingConfirm'),
);

const SystemMonitoringReport = React.lazy(() =>
  import('../SystemMonitoringReport'),
);

// name must be match with id in routerConfig.js
export const samplePagesConfigs = [
  //User management
  {
    path: config.routes.userManage,
    element: <AdminUser />,
    name: ROUTER_NAME.USERS,
  },
  {
    path: config.routes.adminRole,
    element: <AdminRole />,
    name: ROUTER_NAME.ROLE,
  },
  {
    path: config.routes.adminPermission,
    element: <AdminPermissions />,
    name: ROUTER_NAME.PERMISSION,
  },
  {
    path: config.routes.organization,
    element: <OrganizationPage />,
    name: ROUTER_NAME.ORGANIZATION,
  },
  {
    path: config.routes.subsetOrganization,
    element: <SusetOriganizationPage />,
    name: ROUTER_NAME.ORGANIZATION,
  },
  {
    path: config.routes.DetailOrganization,
    element: <DetailOrganizationPage />,
  },

  {
    path: config.routes.documentType,
    element: <DocumentTypePage />,
    name: ROUTER_NAME.DOCUMENT_TYPE,
  },
  {
    path: config.routes.formSetting,
    element: <FormSettingPage />,
    name: [ROUTER_NAME.FORM_SETTING_ALL, ROUTER_NAME.CONFIG_INTEGRATE],
  },
  {
    path: config.routes.formSettingVerdict,
    element: <FormSettingVerdict />,
    name: ROUTER_NAME.FORM_SETTING_VERDICT,
  },
  {
    path: config.routes.formSettingPolice,
    element: <FormSettingPolice />,
    name: ROUTER_NAME.FORM_SETTING_POLICE,
  },
  {
    path: config.routes.formSettingCourt,
    element: <FormSettingCourt />,
    name: ROUTER_NAME.FORM_SETTING_COURT,
  },
  {
    path: config.routes.formSettingProcuracy,
    element: <FormSettingProcuracy />,
    name: ROUTER_NAME.FORM_SETTING_PROCURACY,
  },
  {
    path: config.routes.formSettingExecutionJudgment,
    element: <FormSettingExecutionJudgment />,
    name: ROUTER_NAME.FORM_SETTING_EXECUTION_JUDGMENT,
  },
  {
    path: config.routes.formSettingDecisionJudiciary,
    element: <FormSettingDecisionJudiciary />,
    name: ROUTER_NAME.FORM_SETTING_DECISION_JUDICIARY,
  },

  {
    path: config.routes.listWaitingDigital,
    element: <ListWaitingDigital />,
    name: ROUTER_NAME.FORM_SETTING_DECISION_JUDICIARY,
  },
  {
    path: config.routes.listDocumentWaitingConfirm,
    element: <ListDigitizedDocumentsWaitingConfirm />,
    name: ROUTER_NAME.FORM_SETTING_DECISION_JUDICIARY,
  },
  {
    path: config.routes.serviceParamsConfig,
    element: <ServiceParamsConfigPage />,
    name: [ROUTER_NAME.FORM_SETTING_ALL, ROUTER_NAME.SERVICE_PARAMS_CONFIG],
  },

  {
    path: config.routes.rawDocument,
    element: <RawDocumentPage />,
    name: ROUTER_NAME.RAW_DOCUMENT,
  },
  {
    path: `${config.routes.rawDocument}/:id`,
    element: <EditRawDocumentPage />,
    name: [
      ROUTER_NAME.RAW_DOCUMENT,
      ROUTER_NAME.RAW_DOCUMENT_POLICE,
      ROUTER_NAME.RAW_DOCUMENT_COURT,
      ROUTER_NAME.RAW_DOCUMENT_JUSTICE_LGSP,
      ROUTER_NAME.RAW_DOCUMENT_JUDGMENT_EXECUTION,
      ROUTER_NAME.RAW_DOCUMENT_CONTROL_INSTITUTE,
      ROUTER_NAME.WAITING_DIGITIZE_DOCUMENT,
    ],
  },
  {
    path: config.routes.detailRawDocument(':id'),
    element: <DetailRawDocumentPage />,
    name: [
      ROUTER_NAME.RAW_DOCUMENT,
      ROUTER_NAME.RAW_DOCUMENT_POLICE,
      ROUTER_NAME.RAW_DOCUMENT_COURT,
      ROUTER_NAME.RAW_DOCUMENT_JUSTICE_LGSP,
      ROUTER_NAME.RAW_DOCUMENT_JUDGMENT_EXECUTION,
      ROUTER_NAME.RAW_DOCUMENT_CONTROL_INSTITUTE,
      ROUTER_NAME.WAITING_DIGITIZE_DOCUMENT,
    ],
  },
  {
    path: config.routes.policeRecord,
    element: <PoliceRecordPage />,
    name: ROUTER_NAME.RAW_DOCUMENT_POLICE,
  },
  {
    path: config.routes.courtRecord,
    element: <CourtRecordPage />,
    name: ROUTER_NAME.RAW_DOCUMENT_COURT,
  },
  {
    path: config.routes.justiceDepartmentRecord,
    element: <JusticeDepartmentRecordPage />,
    name: ROUTER_NAME.RAW_DOCUMENT_JUSTICE_LGSP,
  },
  {
    path: config.routes.judgmentExecutionRecord,
    element: <JudgmentExecutionRecordPage />,
    name: ROUTER_NAME.RAW_DOCUMENT_JUDGMENT_EXECUTION,
  },
  {
    path: config.routes.controlInstituteRecord,
    element: <ControlInstitutionRecordPage />,
    name: ROUTER_NAME.RAW_DOCUMENT_CONTROL_INSTITUTE,
  },
  {
    path: config.routes.judicialListRecord,
    element: <CreateJudicialRecord />,
    name: [
      ROUTER_NAME.JUDICIAL_CREATE_RECORDS,
      ROUTER_NAME.LIST_JUDICIAL_RECORD,
    ],
  },
  {
    path: config.routes.judicialCreateRecord,
    element: <CreateJudicialRecord />,
    name: [
      ROUTER_NAME.JUDICIAL_CREATE_RECORDS,
      ROUTER_NAME.LIST_JUDICIAL_RECORD,
    ],
  },
  // thong tin LTTP
  {
    path: config.routes.detailJudicialRecord(
      KEY_STATUS_CREATE_JUDICIAL_RECORD.VERIFIED,
      ':id',
    ),
    element: <DetailVerifiedLLTPPage />,
  },

  // xem trang thai dong bo LTTP
  {
    path: config.routes.judicialViewSyncStatus(':id'),
    element: <ViewSyncStatus />,
  },

  {
    path: config.routes.detailJudicialRecord(
      KEY_STATUS_CREATE_JUDICIAL_RECORD.MINISTRY,
      ':id',
    ),
    element: <DetailVerifiedCreateRecordPage />,
  },
  {
    path: config.routes.detailJudicialRecord(
      KEY_STATUS_CREATE_JUDICIAL_RECORD.WAITING,
      ':id',
    ),
    element: <DetailPendingCreateRecordPage />,
  },
  {
    path: config.routes.judicialCreateRecordAdd,
    element: <JudicialRecordAdd />,
    name: ROUTER_NAME.JUDICIAL_CREATE_RECORDS,
  },
  {
    path: config.routes.judicialRecordEdit(':id'),
    element: <JudicialRecordEdit />,
    name: ROUTER_NAME.JUDICIAL_CREATE_RECORDS,
  },
  {
    path: config.routes.judicialRecordVerify,
    element: <JudicialRecordVerifyPage />,
    name: ROUTER_NAME.JUDICIAL_VERIFY_RECORD,
  },
  {
    path: config.routes.judicialRecordVerifyDetail,
    element: <DetailVerifyJudicialPage />,
  },

  {
    path: config.routes.pushConnectApi,
    element: <PushConnectApiPage />,
    name: ROUTER_NAME.MANAGE_API_PUSH,
  },
  {
    path: config.routes.pushConnectApiDetail(':id'),
    element: <DetailPushConnectApiPage />,
  },
  {
    path: config.routes.receiveConnectApi,
    element: <ReceiveConnectApiPage />,
    name: ROUTER_NAME.RECEIVE_CONNECT_API,
  },
  {
    path: config.routes.receiveConnectApiDetail(':id'),
    element: <DetailReceiveConnectApiPage />,
  },

  {
    path: config.routes.extractedDigitizedDocument,
    element: <ExtractedDigitizedDocumentPage />,
    name: ROUTER_NAME.EXTRACTED_DIGITIZED_DOCUMENT,
  },

  {
    path: config.routes.userActionLog,
    element: <UserActionLogPage />,
    name: ROUTER_NAME.USER_ACTION_LOG,
  },
  {
    path: config.routes.accessLog,
    element: <AccessLogPage />,
    name: ROUTER_NAME.USER_ACCESS_LOG,
  },
  {
    path: config.routes.settingLog,
    element: <SettingLogPage />,
    name: ROUTER_NAME.SETTING_LOG,
  },
  {
    path: config.routes.loginStatus,
    element: <LoginStatusPage />,
    name: ROUTER_NAME.LOGIN_STATUS_LOG,
  },

  {
    path: config.routes.helpCenter,
    element: <HelpCenterPage />,
  },
  {
    path: config.routes.listQA,
    element: <ListQAPage />,
    name: ROUTER_NAME.HELP_QA,
  },

  {
    path: config.routes.usageGuideView,
    element: <UsageGuideViewPage />,
  },
  {
    path: config.routes.usageGuideManage,
    element: <UsageGuideManagePage />,
    name: ROUTER_NAME.USAGE_GUIDE_MANAGE,
  },

  {
    path: config.routes.notifications,
    element: <NotificationPage />,
  },

  {
    path: config.routes.shareApiConfig,
    element: <ShareApiListPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_ALL,
  },
  {
    path: config.routes.shareApiDistrict,
    element: <ShareApiDistrictPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_DISTRICT,
  },
  {
    path: config.routes.shareApiDepartment,
    element: <ShareApiDepartmentPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_DEPARTMENT,
  },
  {
    path: config.routes.shareApiProvince,
    element: <ShareApiProvincePage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_PROVINCE,
  },
  {
    path: config.routes.shareApiLgsp,
    element: <ShareApiLgspPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_LGSP,
  },
  {
    path: config.routes.shareApiCity,
    element: <ShareApiCityPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_CITY,
  },
  {
    path: config.routes.shareApiProvinceInformation,
    element: <ShareProvincialInformationPortalPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_PROVINCE_INFORMATION_PORTAL,
  },
  {
    path: config.routes.shareApiProcurement,
    element: <ShareApiProcuracyPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_PROCUREMENT,
  },
  {
    path: config.routes.shareApiCourt,
    element: <ShareApiCourtPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_COURT,
  },
  {
    path: config.routes.shareApiTha,
    element: <ShareApiThaPage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_THA,
  },
  {
    path: config.routes.shareApiPolice,
    element: <ShareApiPolicePage />,
    name: ROUTER_NAME.SHARE_API_CONFIG_POLICE,
  },
  {
    path: config.routes.listSourceData,
    element: <SourceDataPage />,
    name: ROUTER_NAME.LIST_SOURCE_DATA,
  },
  {
    path: config.routes.configDataProcess,
    element: <StreamDataPage />,
    name: ROUTER_NAME.CONFIG_DATA_PROCESS,
  },
  {
    path: config.routes.listSourceApi,
    element: <SourceApiPage />,
    name: ROUTER_NAME.LIST_SOURCE_API,
  },
  {
    path: config.routes.configApiProcess,
    element: <StreamApiPage />,
    name: ROUTER_NAME.CONFIG_API_PROCESS,
  },
  {
    path: config.routes.detailInfoData(':id'),
    element: <DetailInfoDataPage />,
  },
  {
    path: config.routes.searchJudicialRecordsByName,
    element: <SearchJudicialRecordsByName />,
    name: [ROUTER_NAME.JUDICIAL_RECORDS, ROUTER_NAME.CARD_ISSURE_BY_USER],
  },
  {
    path: config.routes.searchJudicialRecordsByIdCard,
    element: <SearchJudicialRecordsByIdCard />,
    name: [ROUTER_NAME.JUDICIAL_RECORDS, ROUTER_NAME.CARD_ISSURE],
  },
  {
    path: config.routes.detailJudicialRecordBDW(':id'),
    element: <DetailJudicialRecordBDW />,
  },

  // bao cao tong hop
  {
    path: config.routes.synthesisReport,
    element: <SynthesisReport />,
    name: ROUTER_NAME.REPORT_SYSTEM,
  },

  {
    path: config.routes.detailSynthesisReport(':id'),
    element: <DetailSynthesisReport />,
  },
  // bao cao giam sat he thong
  {
    path: config.routes.systemMonitoringReport,
    element: <SystemMonitoringReport />,
    name: ROUTER_NAME.REPORT_SYSTEM,
  },
];
