import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AntModal from 'src/@crema/component/AntModal';
import { useDataTableContext } from 'src/@crema/core/DataTable/DataTableContext';
import { instanceCoreApi } from 'src/@crema/services/setupAxios';
import { generateUniqueID } from 'src/@crema/utility/Utils';
import { FORM_CONFIG } from 'src/shared/constants/FormDataTable';
import {
  getMessageResponse,
  getErrorsResponse,
} from 'src/shared/utils/Service';
import { isFunction, isEmpty } from 'src/shared/utils/Typeof';
import IntlMessages from '../../../@crema/utility/IntlMessages';
import { useIntl } from 'react-intl';
import useCallApi from 'src/@crema/hook/useCallApi';
import { Button } from 'antd';
import notification from 'src/shared/utils/notification';
import { convertObjectMapper } from 'src/shared/utils/Modal';
import { METHOD_FETCH } from 'src/shared/constants/FetchData';
import FormContent from 'src/@crema/component/FormContent';

const FormRowDataTable = (props) => {
  const refForm = useRef();
  const refFormId = useRef(`form-table-${generateUniqueID()}`);
  const {
    noAction = false,
    readOnly = false,
    title,
    buttonText,
    formType,
    visible,
    onClose,
    preSaveData,
    children,
    resource,
    fieldMapper,
    initialValues,
    size,
    method,
    layout = 'vertical',
    customErrorMessage,
    onSuccess,
    ...restProps
  } = props;

  const { messages } = useIntl();
  const { reloadPage } = useDataTableContext();

  const onSaveToServer = (data) => {
    if (method === METHOD_FETCH.PUT) {
      return instanceCoreApi.put(resource, data);
    } else {
      return instanceCoreApi.post(resource, data);
    }
  };

  const configForm = FORM_CONFIG.find(({ type }) => type === formType) || {};

  const { loading, send } = useCallApi({
    success: (response, request) => {
      if (isFunction(onSuccess)) {
        onSuccess(response, request);
      }
      reloadPage();
      onClose();
      notification.success(messages[configForm?.successMessage]);
    },
    callApi: onSaveToServer,
    error: (err) => {
      const messageError = customErrorMessage || getMessageResponse(err);
      const errors = getErrorsResponse(err?.raw);
      if (isEmpty(errors)) {
        notification.error(messages[messageError] || messageError);
      } else {
        const errorMapper = convertObjectMapper({ errors, fieldMapper });
        if (refForm?.current?.setFields) {
          refForm.current.setFields(errorMapper);
        }
      }
    },
  });

  const onSave = (data) => {
    let dataSave = data;

    if (isFunction(preSaveData)) {
      dataSave = preSaveData(dataSave);
    }
    if (!dataSave) return;

    return send(dataSave);
  };

  return (
    <AntModal
      title={
        <h3 style={{ margin: 0 }}>
          <IntlMessages id={title || configForm?.title} />
        </h3>
      }
      bodyStyle={{ maxHeight: '75vh', overflow: 'auto' }}
      onCancel={onClose}
      afterClose={() => {
        refFormId.current = `form-table-${generateUniqueID()}`;
      }}
      centered
      open={visible}
      footer={
        <>
          <Button htmlType='button' onClick={onClose}>
            <IntlMessages
              id={
                configForm?.configCancelText
                  ? configForm?.configCancelText
                  : 'common.cancel'
              }
            />
          </Button>
          {!noAction && (configForm.buttonText || buttonText) && (
            <Button
              loading={loading}
              form={refFormId.current}
              type='primary'
              htmlType='submit'>
              <IntlMessages id={buttonText || configForm.buttonText} />
            </Button>
          )}
        </>
      }
      size={size}>
      <FormContent
        key={refFormId.current}
        id={refFormId.current}
        ref={refForm}
        initialValues={initialValues}
        layout={layout}
        onFinish={onSave}
        disabled={readOnly}
        {...restProps}>
        {children}
      </FormContent>
    </AntModal>
  );
};

FormRowDataTable.propTypes = {
  title: PropTypes.string,
  initialValues: PropTypes.object,
  fieldMapper: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  formType: PropTypes.string,
  preSaveData: PropTypes.func,
  resource: PropTypes.string,
  size: PropTypes.string,
  layout: PropTypes.string,
  method: PropTypes.string,
  onSuccess: PropTypes.func,
};

FormRowDataTable.defaultProps = {
  initialValues: {},
  fieldMapper: {},
  preSaveData: null,
  method: 'post',
};

export default FormRowDataTable;
