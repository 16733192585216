import { findIndex } from 'lodash';
import { isFunction, isObject } from 'src/shared/utils/Typeof';

export const updateItem = (data, condition, mergeObject, force = false) => {
  const arr = [...data];
  const index = findIndex(arr, condition);
  if (index !== -1) {
    const itemUpdate = arr[index];
    let dataUpdate = {};
    if (isFunction(mergeObject)) {
      dataUpdate = mergeObject(itemUpdate, index, arr);
    } else if (isObject(mergeObject)) {
      dataUpdate = mergeObject;
    }

    if (force) {
      arr.splice(index, 1, dataUpdate);
    } else {
      arr.splice(index, 1, { ...itemUpdate, ...dataUpdate });
    }
  }

  return arr;
};

export const onDropTree = (gData, info) => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

  const loop = (data, key, callback) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  const data = [...gData];

  // Find dragObject
  let dragObj;
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, (item) => {
      item.children = item.children || [];
      // where to insert 示例添加到头部，可以是随意位置
      item.children.unshift(dragObj);
    });
  } else if (
    (info.node.props.children || []).length > 0 && // Has children
    info.node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item) => {
      item.children = item.children || [];
      // where to insert 示例添加到头部，可以是随意位置
      item.children.unshift(dragObj);
      // in previous version, we use item.children.push(dragObj) to insert the
      // item to the tail of the children
    });
  } else {
    let ar = [];
    let i;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }
  return data;
};

export function findAddedAndRemovedItems(initialItems, changedItems) {
  initialItems = Array.isArray(initialItems) ? initialItems : [];
  changedItems = Array.isArray(changedItems) ? changedItems : [];

  const initialMap = new Map(initialItems.map((item) => [item?.id, item]));
  const changedMap = new Map(changedItems.map((item) => [item?.id, item]));

  const addedItems = changedItems.filter((item) => !initialMap.has(item?.id));
  const removedItems = initialItems.filter((item) => !changedMap.has(item?.id));

  return { addedItems, removedItems };
}
